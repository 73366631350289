/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class Products extends ProductList {
  constructor(country, token) {
    super('api/watches', country, token);
  }

}

export default Products;
