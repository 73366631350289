import React from 'react';
import Search from 'components/nav/Search';
import Dropdown from 'components/Dropdown';

function SearchDropdown() {
  // eslint-disable-next-line max-len
  const iconSearch = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.98 19.22"><title>search</title><path d="M17.57,19.22a1.45,1.45,0,0,1-1-.43L12.9,15a7.94,7.94,0,0,1-4.81,1.62A8.21,8.21,0,0,1,0,8.32,8.22,8.22,0,0,1,8.09,0a8.22,8.22,0,0,1,8.09,8.32,8.44,8.44,0,0,1-1.36,4.62l3.76,3.89a1.41,1.41,0,0,1,0,2A1.45,1.45,0,0,1,17.57,19.22ZM8.09,2.82a5.39,5.39,0,0,0-5.27,5.5,5.39,5.39,0,0,0,5.27,5.5,5.4,5.4,0,0,0,5.28-5.5A5.4,5.4,0,0,0,8.09,2.82Z" fill="#575756"/></svg>;
  const open = () => {
    document.getElementById('main-search').focus();
  };
  return (
    <Dropdown name="search" title={iconSearch} open={open}>
      <Search id="main-search"/>
    </Dropdown>
  );
}

export default SearchDropdown;