/* eslint no-return-assign: "error" */
import React from 'react';

class ModalButton extends React.Component {

  constructor(props) {
    super(props);
    this.onBtnClick = this.onBtnClick.bind(this);
  }

  onBtnClick(e) {
    this.props.modalRef.current.onOpen(e);
  }

  render() {
    const { children, name } = this.props;
    const attrs = {
      onClick: this.onBtnClick
    };
    if (name) {
      attrs.className = name;
    }
    return (
      <button type="button" {...attrs}>{children}</button>
    );
  }
}

export default ModalButton;
