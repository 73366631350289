import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import sMats from 'assets/s_mats.jpg';
import StrapSearchForm from 'components/StrapSearchForm';
import { Link, Switch, Route } from 'react-router-dom';
import StrapPageMaterials from './StrapPageMaterials';
import AppContext from 'utils/AppContext';

function StrapPage() {
  const { t } = useTranslation();
  const { country, token } = useContext(AppContext);

  return (
    <Switch>
      <Route exact path="/straps-page">
        <div className="straps links-page">
          <div className="container">
            <div className="grid">
              <div className="col-2 m-col-1">
              </div>
              <div className="col-2 m-col-3">
                <h1>{t('Straps Accessorization')}</h1>
                <h2>{t('straps.accessorization.subtitle')}</h2>
                <div className="grid subgrid-std nowrap">
                  <div className="col-1 imagelink">
                    <Link to="/straps-page/materials">
                      <img src={sMats} alt=""/>
                      { t('Straps materials') }
                    </Link>
                  </div>
                  <div className="col-3">
                    <h3>{t('Straps Accessorization')}</h3>
                    <StrapSearchForm id="strap-search" country={country} token={token} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Route>
      <Route path="/straps-page/materials" component={StrapPageMaterials} />
    </Switch>
  );
}

export default StrapPage;
