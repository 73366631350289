import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return (/iphone|ipad|ipod|safari/.test(userAgent) && userAgent.search('chrome') < 0);
};

const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

function AddHomeScreen() {
  const { t } = useTranslation();
  const [bannerVisible, setBannerVisible] = useState(true);
  
  const shouldRender = bannerVisible && isIos() && !isInStandaloneMode();
  
  const showInfos = () => {
    /* eslint-disable-next-line no-alert */
    alert(t('1. Open Share menu\n2. Tap on "Add to Home Screen" button'));
    setBannerVisible(false);
  };
  
  const close = () => {
    setBannerVisible(false);
  };
  
  if (shouldRender) {
    return (
      <div className="add-home">
        <button type="button" className="btn-show" onClick={showInfos}>{t('Add to home screen')}</button>
        <button type="button" className="btn-close" onClick={close}>{t('Close')}</button>
      </div>
    );
  }
  return null;
}

export default AddHomeScreen;