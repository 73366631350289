/* eslint-disable jsx-a11y/media-has-caption,
                  react/no-unescaped-entities */

import Faq from 'components/Faq';
import React from 'react';
import { useTranslation } from 'react-i18next';

function AboutPage() {
  const { t } = useTranslation();
  return (
    <div className="about-page links-page">
      <div className="container">
        <div className="grid">
          <div className="col-1">
          </div>
          <div className="col-3">
            <h1>{t('About this tool')}</h1>
            <p>{t('App version')}: { process.env.REACT_APP_VERSION }</p>
            <div className="video">
              <iframe
                title="video"
                src={`https://www.youtube.com/embed/F9xRdrU_x50?rel=0&modestbranding=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </div>
            <Faq />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage
