import React from 'react';

function Warn(props) {
  return (
    <div className="note">
      <span className="icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <path
            stroke="white"
            strokeWidth="2px"
            fill="transparent"
            // eslint-disable-next-line max-len
            d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z" />
          <rect x="23" y="34" width="4" height="4" fill="white" />
          <rect x="23" y="12" width="4" height="18" fill="white" />
        </svg>
      </span>
      <span>
        {props.children}
      </span>
    </div>
  );
}

export default Warn;