import React from 'react';
import { useTranslation } from 'react-i18next';
import linkto from 'assets/s_linkto.jpg';

function StrapPage() {
  const { t } = useTranslation();
  return (
    <div className="customer-services links-page">
      <div className="container">
        <div className="grid">
          <div className="col-2 m-col-1">
          </div>
          <div className="col-2 m-col-3">
            <h1>{t('Customer services')}</h1>
            <h2>{t('straps.subtitle')}</h2>
            <div className="links">
              <a
                href={process.env.REACT_APP_CS_BASE_URL} 
                rel="noopener noreferrer" 
                target="_blank">
                <img src={linkto} alt=""/>
                {t('Order spare parts components')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrapPage
