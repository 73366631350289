import React, { useContext } from 'react';
import ProductsList from 'components/productlist/ProductsList';
import AppContext from 'utils/AppContext';

function NoveltiesPage(props) {
  const context = useContext(AppContext);
  return (
    <div className="container">      
      <ProductsList
        key="noveltieslist"
        showBreadcrumb={false}
        default_sort="novelties_desc" 
        collection={props.match.params.pk ?? null}
        isBackBtn={props.isBackBtn}
        filtersHash={props.filtersHash}
        store={context.store}
        country={context.country}
        isNovelties />
    </div>
  );
}

export default NoveltiesPage;
