/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class ProductsGrouped extends ProductList {
  constructor(country, token) {
    super('api/watches/vm', country, token);
  }

}

export default ProductsGrouped;
