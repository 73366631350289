import React from 'react';
import Filters from 'components/filters/Filters';
import Sort from 'components/filters/Sort';
import FilterButtons from 'components/filters/FilterButtons';

function FilterNav(props) {
  if (props.meta.count < 1 && !props.hasFilters) {
    return null;
  }

  return (
    <nav className="filters-nav grid">
      <div className="col-3 s-col-1">
        <div className="filters-container">
          <Filters meta={props.meta} productsPage={props.productsPage} />
        </div>
      </div>
      <div className="s-col-1">
        <Sort
          productsPage={props.productsPage}
          enable_novelties={props.enable_novelties}
          enable_search={props.enable_search} />
      </div>
      <FilterButtons meta={props.meta} productsPage={props.productsPage} />
    </nav>
  );
}

export default FilterNav;
