/* eslint-disable no-lonely-if */
import { appActions } from 'utils/AppContext';
import { HttpError } from 'utils/Errors';
import fetchWithTimeout from 'utils/fetchWithTimeout';

class Model {
  constructor(endpoint, timeout) {
    this._lang = 'en';
    this._data = null;
    this._response_headers = null;
    this._getParams = [];
    this.absolute = endpoint.substring(0, 4) === 'http';
    this.endpoint = endpoint;
    this.parseEndpoint(endpoint);
    this.headers = new Headers();
    this._method = 'GET';
    this._timeout = timeout || 20000;
  }

  set lang(lang) {
    this.setGetParamCheck('lang', lang);
    this._lang = lang;
  }

  set method(method) {
    this._method = method;
  }

  get method() {
    return this._method;
  }

  set data(data) {
    this._data = data;
  }

  get data() {
    return this._data;
  }

  set response_headers(response_headers) {
    this._response_headers = response_headers;
  }

  get response_headers() {
    return this._response_headers;
  }

  setGetParam(name, value) {
    this._getParams.push([name, value]);
  }

  setGetParamCheck(name, value) {
    const ind = this._getParams.findIndex(el => el[0] === name);
    if (ind !== -1) {
      this._getParams[ind][1] = value;
    }
    else {
      this._getParams.push([name, value]);
    }
  }

  parseEndpoint(endpoint) {
    this.parts = [];
    this.params = {};
    if (!this.absolute) endpoint.split('/').forEach((part) => { this.checkPart(part) });
  }

  checkPart(part) {
    const match = part.match(/^{([a-zA-Z\-_0-9]+)}$/);
    if (match !== null) {
      const variable = match[1];
      this.parts.push('#' + variable);
      this.params[match[1]] = null;
      if (variable === 'lang') this.lang = this._lang;
    }
    else {
      this.parts.push(part);
    }
  }

  getUrl() {
    if (this.absolute) return this.endpoint;
    let url = process.env.REACT_APP_API_DOMAIN + '/' + this.parts.map(function(part) {
      if (part.substring(0, 1) === '#') {
        return this.params[part.substring(1)];
      }
      return part;
    }.bind(this)).join('/');
    if (this._getParams.length > 0) {
      url += '?' + this._getParams.map(part => part[0] + '=' + part[1]).join('&');
    }
    return url;
  }

  onFetch() {

  }

  onFail(component, errorCode, error, response) {

  }

  fetch(component, callback, errorsHandlers) {
    return fetchWithTimeout(this.getUrl(), { timeout: this._timeout, method: this.method, headers: this.headers })
      .then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            this.data = result;
            this.response_headers = response.headers;
            this.onFetch(result);
            if (callback) {
              callback(this);
            }
            else {
              component.setState({
                isLoaded: true,
                data: this
              });
            }
          });
        }
        else {
          if (errorsHandlers && ('on404' in errorsHandlers) && response.status === 404) {
            errorsHandlers.on404(response);
          }
          else if (errorsHandlers && ('onError' in errorsHandlers)) {
            errorsHandlers.onError(response);
          }
          else {
            appActions.setError(new HttpError(response.status, response.status, response));
          }
        }
      }).catch(error => {
        if (errorsHandlers && ('onFail' in errorsHandlers)) {
          errorsHandlers.onFail(error);
        }
        else {
          appActions.setError(error);
        }
      });
  }
}

export default Model;
