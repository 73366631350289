import React from 'react';

function SeparatedTitle(props) {
  const parts = [];
  if (props.value.substring(0, 7).toUpperCase() === 'TISSOT ') {
    parts.push(<span className="sub" key="sub">TISSOT</span>);
    parts.push(<span className="main" key="main">{props.value.substring(7)}</span>);
  }
  else {
    parts.push(props.value);
  }
  return parts;
}

export default SeparatedTitle;