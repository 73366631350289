import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import getI18nEn from 'i18nEn';

function Group(props) {
  const { handleClick, color, active } = props;
  return (
    <g onClick={() => handleClick(color)} className={active === color ? 'active' : ''}>
      { props.children }
    </g>
  )
}

function GuidelinesPageRule(props) {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const { id, title, paths, image, layout } = props;
  const [activeRule, setActiveRule] = useState(null);

  const handleClick = (color) => {
    if (activeRule === color) {
      setActiveRule(null);
    }
    else {
      setActiveRule(color);
    }
  };

  const col1 = (
    <div className="visual">
      <img src={image} alt="" />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 991 660">
        <defs>
          <style>{`
            .cls {
              pointer-events: visibleFill;
              fill: none;
              stroke-width: 3.35px;
            }
            .cls-1 {
              stroke: #ee7573;
            }
            .cls-2 {
              stroke: #ffe500;
            }
            .cls-3 {
              stroke: #1b9dd9;
            }
            .cls-4 {
              stroke: #264e9b;
            }
            .cls-5 {
              stroke: #e30613;
            }
          `}</style>
        </defs>
        <Group handleClick={handleClick} color="salmon" active={activeRule}>
          { paths.salmon }
        </Group>
        <Group handleClick={handleClick} color="yellow" active={activeRule}>
          { paths.yellow }
        </Group>
        <Group handleClick={handleClick} color="cyan" active={activeRule}>
          { paths.cyan }
        </Group>
        <Group handleClick={handleClick} color="blue" active={activeRule}>
          { paths.blue }
        </Group>
        <Group handleClick={handleClick} color="red" active={activeRule}>
          { paths.red }
        </Group>
      </svg>
    </div>
  );

  const col2 = (
    <>
      { title && (
        <h2 className="main">{ title }</h2>
      )}
      <h3 className="main">{ t('Positioning') }</h3>
      <ul>
        { paths.yellow !== null && (
          <li className={activeRule === 'yellow' ? 'active' : ''}>
            <span className="square" style={{ borderColor: '#ffe500' }}></span>
            <button type="button" onClick={() => handleClick('yellow')}>{ t('In line with visual') }</button>
          </li>
        )}
        { paths.cyan !== null && (
          <li className={activeRule === 'cyan' ? 'active' : ''}>
            <span className="square" style={{ borderColor: '#1b9dd9' }}></span>
            <button type="button" onClick={() => handleClick('cyan')}>{ t('T-classic collections') }</button>
          </li>
        )}
        { paths.blue !== null && (
          <li className={activeRule === 'blue' ? 'active' : ''}>
            <span className="square" style={{ borderColor: '#264e9b' }}></span>
            <button type="button" onClick={() => handleClick('blue')}>{ t('T-lady collections') }</button>
          </li>
        )}
        { paths.red !== null && (
          <li className={activeRule === 'red' ? 'active' : ''}>
            <span className="square" style={{ borderColor: '#e30613' }}></span>
            <button type="button" onClick={() => handleClick('red')}>{ t('T-sport collections') }</button>
          </li>
        )}
        { paths.salmon !== null && (
          <li className={activeRule === 'salmon' ? 'active' : ''}>
            <span className="square" style={{ borderColor: '#ee7573' }}></span>
            <button type="button" onClick={() => handleClick('salmon')}>{ t('T-gold, T-pocket & Heritage collections') }</button>
          </li>
        )}
      </ul>
    </>
  );

  const attrs = {
    className: 'content content-rules'
  };
  if (props.addClass) attrs.className += ' ' + props.addClass;
  attrs.className += ' ' + (layout === 'even' ? 'even' : 'odd');
  if (id) attrs.id = id;
  return (
    <div {...attrs}>
      <div className="container">

        <div className="grid">
          { layout === 'even' && (
            <>
              <div className="col-1 m-col-2 s-col-2">
                { col2 }
                { props.children }
              </div>
              <div className="col-3 m-col-2 s-col-2 trailer-1">
                { col1 }
              </div>
            </>
          ) }
          { layout !== 'even' && (
            <>
              <div className="col-3 m-col-2 s-col-2 trailer-1">
                { col1 }
              </div>
              <div className="col-1 m-col-2 s-col-2">
                { col2 }
                { props.children }
              </div>
            </>
          ) }
        </div>
      </div>
    </div>
  );
}

export default GuidelinesPageRule;
