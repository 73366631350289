import React, { useContext } from 'react';
import AppContext from 'utils/AppContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import current from 'assets/current.jpg';
import about from 'assets/about.jpg';
import technical from 'assets/technical.jpg';
import novelties from 'assets/novelties.jpg';
import straps from 'assets/straps.jpg';
import merch from 'assets/merch.jpg';
import customerServices from 'assets/customer_services.jpg';

function HomePage() {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  /* eslint-disable jsx-a11y/media-has-caption,
                    react/no-unescaped-entities */
  return (
    <div className="container">
      <div className="homepage grid">
        <div className="col-2">
          <Link className="big" to="/collection" onClick={() => context.resetFilters()} style={{ backgroundImage: `url(${current})` }}>
            <div className="text">
              <h2>{t('Current collections')}</h2>
            </div>
          </Link>
          <div className="grid">
            <Link className="small" to="/customer-services" style={{ backgroundImage: `url(${customerServices})` }}>
              <div className="text">
                <h2>{t('Customer services')}</h2>
              </div>
            </Link>
            <Link className="small" to="/merchandising" style={{ backgroundImage: `url(${merch})` }}>
              <div className="text">
                <h2>{t('Merchandising')}</h2>
              </div>
            </Link>
          </div>
        </div>
        <div className="col-2">
          <div className="grid">
            <Link className="small" to="/about_tissot/about" style={{ backgroundImage: `url(${about})` }}>
              <div className="text">
                <h2>{t('About Tissot')}</h2>
              </div>
            </Link>
            <Link className="small" to="/technical_features" style={{ backgroundImage: `url(${technical})` }}>
              <div className="text">
                <h2>{t('Technical features')}</h2>
              </div>
            </Link>
          </div>
          <Link className="big half shadow" to="/novelties" style={{ backgroundImage: `url(${novelties})` }}>
            <div className="text">
              <h2>{t('Novelties')}</h2>
            </div>
          </Link>
          <Link className="big half" to="/straps-page" style={{ backgroundImage: `url(${straps})` }}>
            <div className="text">
              <h2>{t('Straps Accessorization')}</h2>
            </div>
          </Link>
        </div>
      </div>
      <div className="homepage-mobile">
        <Link to="/collection" onClick={() => context.resetFilters()}>
          <div className="text">
            <h2>{t('Current collections')}</h2>
          </div>
        </Link>
        <Link to="/novelties">
          <div className="text">
            <h2>{t('Novelties')}</h2>
          </div>
        </Link>
        <Link to="/straps-page">
          <div className="text">
            <h2>{t('Straps Accessorization')}</h2>
          </div>
        </Link>
        <Link to="/customer-services">
          <div className="text">
            <h2>{t('Customer services')}</h2>
          </div>
        </Link>
        <Link to="/about_tissot/about">
          <div className="text">
            <h2>{t('About Tissot')}</h2>
          </div>
        </Link>
        <Link to="/technical_features">
          <div className="text">
            <h2>{t('Technical features')}</h2>
          </div>
        </Link>
        <Link to="/merchandising">
          <div className="text">
            <h2>{t('Merchandising')}</h2>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default HomePage;
