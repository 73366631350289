import React from 'react';
import ErrorMessage from 'components/ErrorMessage';
import MessageBox from 'components/MessageBox';
import logo from 'logo.svg';
import { HttpError } from 'utils/Errors';

function ErrorPage(props) {
  const { error, message, componentStack } = props;
  let extra = null;
  const maintenanceMode = error instanceof HttpError && error.code === 503;

  if (!maintenanceMode && ['stage', 'dev'].includes(process.env.REACT_APP_SENTRY_ENVIRONMENT)) {
    extra = (
      <>
        <p>{ error.toString() }</p>
        <div>
          { componentStack }
        </div>
      </>
    );
  }

  return (
    <div className="base">
      <header>
        <nav className="navbar">
          <div className="container">
            <div className="menu-icons">
              <a href="/"><img className="logo" src={logo} alt="TISSOT" /></a>
            </div>
          </div>
        </nav>
      </header>
      <div className="main">
        <MessageBox type="error" context="full" size={extra === null ? 'normal' : 'big'}>
          <ErrorMessage
            error={error}
            message={message}>
            { extra }
          </ErrorMessage>
        </MessageBox>
      </div>
      <footer>
        © TISSOT SA - SWISS WATCHES SINCE 1853 - ALL RIGHT RESERVED
      </footer>
    </div>
  );
}

export default ErrorPage;
