import React from 'react';
import getI18nEn from 'i18nEn';
import { useTranslation } from 'react-i18next'

function Ref(props) {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  return (
    <strong>{ t('Ref.') } {props.children}</strong>
  );
}

export default Ref;