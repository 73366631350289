import React from 'react';
import { useTranslation } from 'react-i18next'

function CustomerServiceLink(props) {
  const { t } = useTranslation();
  const csURL = process.env.REACT_APP_CS_URL.replace('{sku}', props.product.sku)
  return (
    <a className="btn" href={csURL} rel="noopener noreferrer" target="_blank">
      {t('Order spare parts components')}
    </a>
  );
}

export default CustomerServiceLink;
