import React from 'react';

function Spinner(props) {
  const color = props.color || 'default';
  const position = props.position || 'flow';
  return (
    <div className={`spinner-container ${position}`}>
      <div className={`spinner ${color}`}>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="circle"></span>
        <span className="text">Loading…</span>
      </div>
    </div>
  );
}

export default Spinner;
