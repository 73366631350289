import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import Storage from 'utils/Storage';

let i18nInstance;

function initI18n() {
  return i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: Storage.getItem('language') || 'fr',
      fallbackLng: 'en',
      nsSeparator: false,
      keySeparator: false,
      interpolation: {
        escapeValue: false
      },
      backend: {
        requestOptions: {
          cache: 'no-store',
        },
      },
    }).then(() => {
      i18nInstance = i18n;
    });
}

function getI18n() {
  return i18nInstance;
}

export { initI18n, getI18n };
