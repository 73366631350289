import React from 'react';
import { useTranslation } from 'react-i18next';
import displaysHome from 'assets/displays-home.svg';
import displaysExample from 'assets/displays_example.jpg';
import ecoColors from 'assets/ecocolors.png';
import ecoConception from 'assets/ecoconception.jpg';
import ecoFriendly from 'assets/ecofriendly.jpg';
import merch from 'assets/merch.jpg';
import dDisplay from 'assets/d_display.jpg';
import dExtension from 'assets/d_extension.jpg';
import dLowcentral from 'assets/d_lowcentral.jpg';
import dCushions from 'assets/d_cushions.jpg';
import dSidebases from 'assets/d_sidebases.jpg';
import dCounterback from 'assets/d_counterback.jpg';
import dSmallback from 'assets/d_smallback.jpg';
import dMedback from 'assets/d_medback.jpg';
import dLargeback from 'assets/d_largeback.jpg';
import dTinyhigh from 'assets/d_tinyhigh.jpg';
import dColhigh from 'assets/d_colhigh.jpg';
import dLargevisu from 'assets/d_largevisu.jpg';
import dSquarevisu from 'assets/d_squarevisu.jpg';
import dBackhigh from 'assets/d_backhigh.jpg';
import dWatchstandset from 'assets/d_watchstandset.jpg';
import dTriangle from 'assets/d_triangle.jpg';
import dCoverplate from 'assets/d_coverplate.jpg';
import setup1 from 'assets/setup-1.jpg';
import setup2 from 'assets/setup-2.jpg';
import setup3 from 'assets/setup-3.jpg';
import fixations from 'assets/fixations.jpg';
import getI18nEn from 'i18nEn';
import Breadcrumb from 'components/nav/Breadcrumb';
import DisplaysPageDisplays from './DisplaysPageDisplays';
import DisplaysPageAdditional from './DisplaysPageAdditional';
import MerchandisingMenu from './MerchandisingMenu';

function DisplaysPage() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const menu = [
    { id: 'concept-presentation', text: t('Concept presentation') },
    { id: 'all-pieces', text: t('All pieces') },
    { id: 'setup-infos', text: t('Set-up information') },
    { id: 'incounter-displays', text: t('Boutique incounter displays') },
    { id: 'backwall-displays', text: t('Boutique backwall displays') },
    { id: 'boutique-sis', text: t('Boutique & SIS safe') },
    { id: 'sis-incounter', text: t('SIS incounter displays') },
    { id: 'sis-T808045467', text: t('SIS T808045467 incounter displays') },
    { id: 'wholesale-incounter', text: t('Wholesale incounter displays') },
    { id: 'wholesale-backwall', text: t('Wholesale backwall displays') },
    { id: 'cushions-display', text: t('Cushions display') },
    { id: 'highlighters-display', text: t('Highlighters') },
    { id: 'watchstand-set', text: t('Watchstand set') },
    { id: 'content-additional', text: t('Additional elements') },
  ];

  const breadcrumb = [
    { path: 'merchandising', name: t('Merchandising') },
    { path: 'merchandising/displays', name: t('Displays And Accessories') }
  ];
  return (
    <div className="merchandising-displays contents-page">
      <div className="container">
        <Breadcrumb menu={breadcrumb} tOptions={{ i18n: getI18nEn() }} />
      </div>
      <div className="content content-head">
        <div className="container">
          <div className="grid">
            <div className="col-2">
              <img className="stacked-left" src={displaysHome} alt="" />
            </div>
            <div className="col-2 above">
              <h1>{t('merchandising.displays.title')}</h1>
              <p className="lead">
                {t('merchandising.displays.lead')}
              </p>
              <img src={displaysExample} alt="" />
            </div>
          </div>
        </div>
        <div className="band"></div>
      </div>
      <MerchandisingMenu elements={menu}/>
      <div className="content content-textimages content-2" id="concept-presentation">
        <div className="container">
          <div className="grid">
            <div className="col-2">
              <div className="image-text trailer-1">
                <img src={ecoConception} alt="" />
                <div className="text big">
                  <h3>{ t('Eco conception') }</h3>
                  <p>{ t('Less references') }</p>
                </div>
              </div>
              <div className="grid">
                <div className="col-2"></div>
                <div className="col-2">
                  <div className="image-text">
                    <img src={ecoColors} alt="" />
                    <div className="text white">
                      <h3>{ t('Premium look & feel') }</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="grid">
                <div className="col-2">
                  <div className="image-text">
                    <img src={ecoFriendly} alt="" />
                    <div className="text">
                      <h3>{ t('Recycled injected material') }</h3>
                      <p>{ t('Paste made of 85% recycled ABS/PET.') }</p>
                    </div>
                  </div>
                </div>
                <div className="col-2"></div>
              </div>
              <div className="text-spaced">
                <h2 className="big">{t('merchandising.displays.ecotitle')}</h2>
                <div
                  className="text-base"
                  /* eslint-disable-next-line react/no-danger */
                  dangerouslySetInnerHTML={{ __html: t('merchandising.displays.ecotext') }}>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="band"></div>
      </div>
      <div className="content content-grid content-3" id="all-pieces">
        <div className="container">
          <div className="grid trailer-1">
            <div className="col-1">
              <div className="image-text">
                <img src={merch} alt="" />
                <div className="text big white center">
                  <p className="upper">{ t('Display') }</p>
                  <h3>{ t('All pieces') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dDisplay} alt="" />
                <div className="text">
                  <h3>{ t('Display') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dLowcentral} alt="" />
                <div className="text">
                  <h3>{ t('Low central base') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dCushions} alt="" />
                <div className="text">
                  <h3>{ t('Cushions display') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="grid trailer-1">
            <div className="col-1">
              <div className="image-text">
                <img src={dExtension} alt="" />
                <div className="text">
                  <h3>{ t('Extension') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dSidebases} alt="" />
                <div className="text">
                  <h3>{ t('Side bases') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dCounterback} alt="" />
                <div className="text">
                  <h3>{ t('Counter backwall') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dSmallback} alt="" />
                <div className="text">
                  <h3>{ t('Small backwall') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="grid trailer-1">
            <div className="col-1">
              <div className="image-text">
                <img src={dMedback} alt="" />
                <div className="text">
                  <h3>{ t('Medium backwall') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dLargeback} alt="" />
                <div className="text">
                  <h3>{ t('Large backwall') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dTinyhigh} alt="" />
                <div className="text">
                  <h3>{ t('Tiny highlighter') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dColhigh} alt="" />
                <div className="text">
                  <h3>{ t('Collection highlighter') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="grid trailer-1">
            <div className="col-1">
              <div className="image-text">
                <img src={dLargevisu} alt="" />
                <div className="text">
                  <h3>{ t('Large visual') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dSquarevisu} alt="" />
                <div className="text">
                  <h3>{ t('Square visual') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dBackhigh} alt="" />
                <div className="text">
                  <h3>{ t('Backwall highlighter') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dWatchstandset} alt="" />
                <div className="text">
                  <h3>{ t('Watchstand set') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="grid trailer-1">
            <div className="col-1">
              <div className="image-text">
                <img src={dTriangle} alt="" />
                <div className="text">
                  <h3>{ t('Triangle logo') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
              <div className="image-text">
                <img src={dCoverplate} alt="" />
                <div className="text">
                  <h3>{ t('Logo "cover plate"') }</h3>
                </div>
              </div>
            </div>
            <div className="col-1">
            </div>
            <div className="col-1">
            </div>
          </div>

        </div>
      </div>
      <div className="content setup-informations" id="setup-infos">
        <div className="container">
          <h2 className="main">{ t('Set-up information') }</h2>
          <div className="grid">
            <div className="col-1-3">
              <img src={setup1} alt=""/>
              <p>To join central base and central extensions together, use fixation pieces.</p>
            </div>
            <div className="col-1-3">
              <img src={setup2} alt=""/>
              <p>It is mandatory to use the fixations when positioning
                the display vertically for security reasons.</p>
            </div>
            <div className="col-1-3">
              <img src={setup3} alt=""/>
              <p>Fix two pieces on each side of the display.</p>
            </div>
          </div>
          <div className="grid grid-mobile-reverse grid-small-reverse">
            <div className="col-1-3">
              <p className="p-m-top">To join large visual to cushion displays together, use fixation pieces given with large visual.</p>
            </div>
            <div className="col-2-3">
              <img src={fixations} alt=""/>
            </div>
          </div>
        </div>
      </div>
      <DisplaysPageDisplays />
      <DisplaysPageAdditional />
    </div>
  );
}

export default DisplaysPage;
