/* eslint-disable react/no-unused-state, react/no-danger */
import React from 'react';
import { withTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';
import AppContext from 'utils/AppContext';
import ProductsGrouped from 'models/ProductsGrouped';
import WatchGroup, { getFamilyImage } from 'components/WatchGroup';

class OtherFamilyProducts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null
    };
    this.products = null;
  }

  load() {
    const { country, token } = this.context;
    const other_family_products = new ProductsGrouped(country, token);
    other_family_products.page_size = 50;
    other_family_products.lang = this.props.i18n.language;
    other_family_products.family = this.props.family;
    other_family_products.onFetch = (data) => this.buildProducts(data);
    other_family_products.fetch(this, null, {
      onError: res => {
        this.setState({ error: true });
      }
    });
  }

  buildProducts(data) {
    if (data.meta.subset.count === 0) {
      this.products = { items: [] };
      return;
    }
    const products = data.items[0];
    products.items.splice(
      products.items.findIndex(i => i.sku === this.props.sku), 1
    )
    products.name += ' ' + this.props.t('other products');
    products.family_front_image = getFamilyImage(products.image_start);
    products.family_back_image = getFamilyImage(products.image_end);
    this.products = products;
  }

  componentDidMount() {
    this.load();
  }

  render() {
    const { error, isLoaded } = this.state;

    if (error) {
      return null;
    } else if (!isLoaded) {
      return <Spinner/>;
    } else if (this.products.items.length < 1) {
      return null;
    } else {
      return (
        <div className="watches-list">
          <WatchGroup item={this.products} />
        </div>
      )
    }
  }
}
OtherFamilyProducts.contextType = AppContext;

export default withTranslation()(OtherFamilyProducts);
