import React from 'react';
import { useTranslation } from 'react-i18next'
import dOldLarge from 'assets/d_old_largec.jpg';
import dOldMedInc from 'assets/d_old_medinc.jpg';
import dOldLargeInc from 'assets/d_old_largeinc.jpg';
import dNewDisplay from 'assets/d_new_display.jpg';
import dNewCounterbDisplay from 'assets/d_new_counterb-display.jpg';
import dNewBdisplay from 'assets/d_new_bdisplay.jpg';
import dOldSmall from 'assets/d_old_small.jpg';
import dOldBackwall from 'assets/d_old_backwall.jpg';
import dOldSafe from 'assets/d_old_safe.jpg';
import dNewSafe from 'assets/d_new_safe.jpg';
import dNewSafeZoom from 'assets/d_new_safezoom.jpg';
import dOldCushion from 'assets/d_old_cushions.jpg';
import dNewLowcentralb from 'assets/d_new_lowcentralb.jpg';
import dOldLargeincounter from 'assets/d_old_largeincounter.jpg';
import dOldSmallincounter from 'assets/d_old_smallincounter.jpg';
import dOldMedback from 'assets/d_old_medback.jpg';
import dOldSmedback from 'assets/d_old_smedback.jpg';
import dNewMedb from 'assets/d_new_medb.jpg';
import dNewLargeb from 'assets/d_new_largeb.jpg';
import dOldLargeback from 'assets/d_old_largeb.jpg';
import dNewCushdis from 'assets/d_new_cushdis.jpg';
import dOldSISLargeInc from 'assets/d_old_sislargecount.jpg';
import dOldCushback from 'assets/d_old_cushback.jpg';
import dNewSmallBackwall from 'assets/d_new_smallbackwall.jpg';
import dOldSmallDisplay from 'assets/d_old_smalldisplay.jpg';
import dOldWalldis from 'assets/d_old_walldis.jpg';
import dOldTinyback from 'assets/d_old_tinyback.jpg';
import dNewWatchstand from 'assets/d_new_watchstand.jpg';
import dOldWatchstand from 'assets/d_old_watchstand.jpg';
import dNewHighlighter from 'assets/d_new_highlighter.jpg';
import dNewTinyHighlighter from 'assets/d_new_tiny_highlighter.jpg';
import opBackwall from 'assets/op-backwall.svg';
import opTriangle from 'assets/op-triangle-logo.svg';
import opCoverplate from 'assets/op-coverplate.svg';
import getI18nEn from 'i18nEn';
import Ref from './Ref';

function Recommended(props) {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const isInterval = 'to' in props;
  const classes = ['recommended'];
  if (isInterval) classes.push('interval');
  return (
    <div className={classes.join(' ')}>
      {isInterval && (
        <>
          <span>{ t('Recommended') }</span>
          <span className="count">{ t('merchandising.displays.n-pieces-interval', { from: props.from, to: props.to }) }</span>
        </>
      )}
      {!isInterval && (
        <>
          <span className="count">{ props.from }</span>
          <span>{ t('Pieces') }</span>
        </>
      )}

      {props.max && (
        <span>{ t('Max. {{ count }} p.', { count: props.max }) }</span>
      )}
    </div>
  );
}
function DisplayOld(props) {
  return (
    <>
      <div className="col-1">
        <img src={props.img} alt="" />
      </div>
      <div className="col-1">
        <h4>{ props.title }</h4>
        { props.children }
      </div>
    </>
  );
}
function DisplayOdd(props) {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const attrs = {
    className: 'display odd'
  };
  if (props.id) attrs.id = props.id;
  return (
    <div {...attrs}>
      <div className="container">
        { 'title' in props && (
          <h2 className="main">{ props.title }</h2>
        ) }
        <div className="grid stretch">
          <div className="col-2 col-img relative">
            <img className="block" src={props.img} alt=""/>
            { props.imgContent }
          </div>
          <div className="col-2 bg-dark relative">
            { props.children[0] }
          </div>
        </div>
        <h2>{ t('Existing') }</h2>
        { props.children[1] }
      </div>
    </div>
  );
}

function DisplayEven(props) {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const attrs = {
    className: 'display even'
  };
  if (props.id) attrs.id = props.id;
  return (
    <div {...attrs}>
      <div className="container">
        { 'title' in props && (
          <h2 className="main">{ props.title }</h2>
        ) }
        <div className="grid stretch">
          <div className="col-2 bg-dark relative">
            { props.children[0] }
          </div>
          <div className="col-2 col-img relative">
            <img className="block" src={props.img} alt=""/>
            { props.imgContent }
          </div>
        </div>
        <h2>{ t('Existing') }</h2>
        { props.children[1] }
      </div>
    </div>
  );
}

function DisplaysPageDisplays() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  return (
    <div className="content content-display">

      <DisplayOdd
        img={dNewDisplay}
        imgContent={<Recommended from="30" to="50" max="60"/>}
        title={t('Boutique incounter displays')}
        id="incounter-displays">
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Display') }</h4>
          <div><Ref>T801046986</Ref></div>
          { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 400, h: 122 }) }<br />
          { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 400, h: 122 }) }
          <h4>{ t('Includes') }</h4>
          <ul className="include-list">
            <li>{ t('merchandising.displays.n-centralbase', { count: 1 }) }</li>
            <li>{ t('merchandising.displays.n-centralext', { count: 1 }) }</li>
            <li>{ t('merchandising.displays.n-sidebase', { count: 2 }) }</li>
            <li>{ t('merchandising.displays.n-sideext', { count: 2 }) }</li>
            <li>{ t('merchandising.displays.n-cush', { count: 60 }) }</li>
            <li>{ t('merchandising.displays.n-cushsup', { count: 35 }) }</li>
            <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
            <li>{ t('merchandising.displays.n-infopsup', { count: 4 }) }</li>
            <li>{ t('merchandising.displays.n-fix', { count: 4 }) }</li>
          </ul>
          <h4>{ t('Optional element') }</h4>
          <div className="inlines">
            <div className="pl">
              1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
              { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
            </div>
            <div className="pt pb">
              <img className="optional" height="25" src={opTriangle} alt=""/>
            </div>
          </div>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldLargeInc} title={t('Large incounter')}>
            <div><Ref>T801039776</Ref></div>
            { t('merchandising.displays.sizes', { w: 900, d: 394, h: 100 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 39 }) }
          </DisplayOld>
          <DisplayOld img={dOldMedInc} title={t('Medium incounter')}>
            <div><Ref>T801039777</Ref></div>
            { t('merchandising.displays.sizes', { w: 650, d: 394, h: 100 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 25 }) }
          </DisplayOld>
          <div className="col-2"></div>
        </div>
      </DisplayOdd>

      <DisplayEven img={dNewBdisplay} imgContent={<Recommended from="56"/>} title={t('Boutique backwall displays')} id="backwall-displays">
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Display') }</h4>
          { t('merchandising.displays.sizes', { w: 1024, d: 122, h: 400 }) }
          <h4>{ t('What to order') }</h4>
          { t('merchandising.displays.n-cushdis', { count: 2 }) } - <Ref>T801046993</Ref><br/>
          { t('merchandising.displays.n-largevisu', { count: 1 }) } - <Ref>T801046997</Ref>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldBackwall} title={t('Backwall')}>
            <div><Ref>T801039772</Ref></div>
            { t('merchandising.displays.sizes', { w: 1080, d: 160, h: 518 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 55 }) }
          </DisplayOld>
          <div className="col-2"></div>
        </div>
      </DisplayEven>

      <div className="display square" id="boutique-sis">
        <div className="container">
          <h2 className="main">{ t('Boutique & SIS safe') }</h2>
          <div className="grid">
            <div className="col-2">
              <img className="block" src={dNewSafe} alt=""/>

              <div className="bg-dark relative leader-1">
                <h2>{ t('New') }</h2>
                <h4>{ t('Safe display black') }</h4>
                <div><Ref>T801045777</Ref></div>
                <Recommended from="110" />
              </div>
            </div>
            <div className="col-2">
              <div className="grid">
                <div className="col-2">
                  <img src={dNewSafeZoom} alt=""/>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <h2>{ t('Existing') }</h2>
                  <img src={dOldSafe} alt="" />
                </div>
                <div className="col-2">
                  <h4>{ t('Safe') }</h4>
                  <div><Ref>T801040731</Ref> / <Ref>T801039773</Ref></div>
                  { t('merchandising.displays.sizes', { w: 1020, d: 488, h: 100 }) }<br/>
                  { t('merchandising.displays.n-pieces', { count: 140 }) }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div id="sis-incounter">
        <DisplayEven img={dNewDisplay} imgContent={<Recommended from="30" to="50" max="60"/>} title={t('SIS incounter displays')}>
          <>
            <h2>{ t('New') }</h2>
            <h4>{ t('Display') }</h4>
            <div><Ref>T801046986</Ref></div>
            { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 400, h: 122 }) }<br />
            { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 400, h: 122 }) }
            <h4>{ t('Includes') }</h4>
            <ul className="include-list">
              <li>{ t('merchandising.displays.n-centralbase', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-centralext', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-sidebase', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-sideext', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-cush', { count: 60 }) }</li>
              <li>{ t('merchandising.displays.n-cushsup', { count: 35 }) }</li>
              <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-infopsup', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-fix', { count: 4 }) }</li>
            </ul>
            <h4>{ t('Optional element') }</h4>
            <div className="inlines">
              <div className="pl">
                1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
                { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
              </div>
              <div className="pt pb">
                <img className="optional" height="25" src={opTriangle} alt=""/>
              </div>
            </div>
          </>
          <div className="grid middle">
            <DisplayOld img={dOldLarge} title={t('Large counter')}>
              <div><Ref>T801039999</Ref></div>
              { t('merchandising.displays.sizes', { w: 1020, d: 488, h: 100 }) }<br/>
              { t('merchandising.displays.n-pieces', { count: 74 }) }
            </DisplayOld>
            <div className="col-2"></div>
          </div>
        </DisplayEven>
        <DisplayOdd img={dNewCounterbDisplay} imgContent={<Recommended from="30" to="50" max="60" />}>
          <>
            <h2>{ t('New') }</h2>
            <h4>{ t('Counter backwall display') }</h4>
            { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 420, h: 190 }) }<br />
            { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 420, h: 190 }) }
            <h4>{ t('What to order') }</h4>
            <ul className="include-list">
              <li>{ t('merchandising.displays.n-display', { count: 1 }) } - <Ref>T801046986</Ref></li>
              <li>{ t('merchandising.displays.n-counterb', { count: 1 }) } - <Ref>T801047186</Ref></li>
            </ul>
            <h4>{ t('Optional element') }</h4>
            <div className="inlines">
              <div className="pl">
                1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
                { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
              </div>
              <div className="pt pb">
                <img className="optional" height="25" src={opTriangle} alt=""/>
              </div>
            </div>
          </>
          <div className="grid middle">
            <DisplayOld img={dOldCushion} title={t('Cushions')}>
              <div><Ref>T801037652</Ref></div>
              { t('merchandising.displays.sizes', { w: 942, d: 428, h: 186 }) }<br/>
              { t('merchandising.displays.n-pieces', { count: 56 }) }
            </DisplayOld>
            <div className="col-2"></div>
          </div>
        </DisplayOdd>
      </div>

      <DisplayEven img={dNewLowcentralb} title={t('SIS T808045467 incounter displays')} id="sis-T808045467">
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Low central base set') }</h4>
          <div><Ref>T801046990</Ref></div>
          { t('merchandising.displays.sizes', { w: 392, d: 292, h: 75 }) }
          <div className="upper"><strong>{ t('Only for {{ model }}', { model: 'ONLY FOR T+ SIS PIANO T808045467' }) }</strong></div>
          <h4>{ t('Includes') }</h4>
          <ul className="include-list">
            <li>{ t('merchandising.displays.n-lowcentralbase', { count: 2 }) }</li>
            <li>{ t('merchandising.displays.n-cush', { count: 45 }) }</li>
            <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
          </ul>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldSmall} title={t('Small counter')}>
            <div><Ref>T801040000</Ref></div>
            { t('merchandising.displays.sizes', { w: 830, d: 325, h: 100 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 36 }) }
          </DisplayOld>
          <div className="col-2"></div>
        </div>
      </DisplayEven>

      <DisplayEven
        img={dNewCounterbDisplay}
        imgContent={<Recommended from="30" to="50" max="60" />}
        title={t('Wholesale incounter displays')}
        id="wholesale-incounter">
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Counter backwall display') }</h4>
          { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 420, h: 190 }) }<br />
          { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 420, h: 190 }) }

          <h4>{ t('What to order') }</h4>
          <ul className="include-list">
            <li>{ t('merchandising.displays.n-display', { count: 1 }) } - <Ref>T801046986</Ref></li>
            <li>{ t('merchandising.displays.n-counterb', { count: 1 }) } - <Ref>T801047186</Ref></li>
          </ul>
          <h4>{ t('Optional element') }</h4>
          <div className="inlines">
            <div className="pl">
              1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
              { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
            </div>
            <div className="pt pb">
              <img className="optional" height="25" src={opTriangle} alt=""/>
            </div>
          </div>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldLargeincounter} title={t('Large incounter')}>
            <div><Ref>T801036716</Ref></div>
            { t('merchandising.displays.sizes', { w: 740, d: 436, h: 186 }) }<br/>
            { t('merchandising.displays.n-pieces-interval', { from: 30, to: 60 }) }
          </DisplayOld>
          <DisplayOld img={dOldSmallincounter} title={t('Small incounter')}>
            <div><Ref>T801036717</Ref></div>
            { t('merchandising.displays.sizes', { w: 588, d: 365, h: 186 }) }<br/>
            { t('merchandising.displays.n-pieces-interval', { from: 20, to: 35 }) }
          </DisplayOld>

        </div>
      </DisplayEven>
      <div id="wholesale-backwall">
        <DisplayOdd img={dNewSmallBackwall} imgContent={<Recommended from="23" to="25" max="28"/>} title={t('Wholesale backwall displays')}>
          <>
            <h2>{ t('New') }</h2>
            <h4>{ t('Small backwall display') }</h4>
            { t('merchandising.displays.sizes', { w: 392, d: 430, h: 370 }) }
            <h4>{ t('What to order') }</h4>
            <ul className="include-list">
              <li>{ t('merchandising.displays.n-cushdis', { count: 1 }) } - <Ref>T801046993</Ref></li>
              <li>{ t('merchandising.displays.n-smallb', { count: 1 }) } - <Ref>T801047556</Ref></li>
            </ul>
          </>
          <div className="grid middle">
            <DisplayOld img={dOldSmallDisplay} title={t('Small display')}>
              <div><Ref>T801036721</Ref></div>
              { t('merchandising.displays.sizes', { w: 410, d: '305-375', h: 375 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 15, to: 25 }) }
            </DisplayOld>
            <DisplayOld img={dOldSmallDisplay} title={t('Small display special size')}>
              <div><Ref>T801037692</Ref></div>
              { t('merchandising.displays.sizes', { w: 410, d: '305-375', h: 375 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 15, to: 25 }) }
            </DisplayOld>
          </div>
        </DisplayOdd>
        <DisplayOdd img={dNewMedb} imgContent={<Recommended from="36" to="56" max="66"/>}>
          <>
            <h2>{ t('New') }</h2>
            <h4>{ t('Medium backwall display') }</h4>
            <div><Ref>T801046991</Ref></div>
            { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 420, h: 190 }) }<br />
            { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 420, h: 190 }) }
            <h4>{ t('Includes') }</h4>
            <ul className="include-list">
              <li>{ t('merchandising.displays.n-mediumb', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-centralbase', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-centralext', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-sidebase', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-sideext', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-cush', { count: 60 }) }</li>
              <li>{ t('merchandising.displays.n-cushsup', { count: 35 }) }</li>
              <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-infopsup', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-fix', { count: 4 }) }</li>
            </ul>
            <h4>{ t('Optional elements') }</h4>
            <div className="inlines">
              <div className="pl">
                1x { t('Backwall highlighter') } - <Ref>T801047001478</Ref><br />
                { t('merchandising.displays.sizes', { w: 845, d: 490, h: 550 }) }
              </div>
              <div className="pt pb">
                <img className="optional" src={opBackwall} alt=""/>
              </div>
            </div><br/>
            <div className="inlines">
              <div className="pl">
                1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
                { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
              </div>
              <div className="pt pb">
                <img className="optional" src={opTriangle} alt=""/>
              </div>
            </div>
          </>
          <div className="grid middle">
            <DisplayOld img={dOldMedback} title={t('Medium backwall')}>
              <div><Ref>T801036719</Ref></div>
              { t('merchandising.displays.sizes', { w: 740, d: 436, h: 530 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 36, to: 60 }) }
            </DisplayOld>
            <DisplayOld img={dOldSmedback} title={t('S-medium backwall')}>
              <div><Ref>T801036722</Ref></div>
              { t('merchandising.displays.sizes', { w: 645, d: 355, h: 470 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 30, to: 45 }) }
            </DisplayOld>
          </div>
        </DisplayOdd>
        <DisplayEven img={dNewLargeb} imgContent={<Recommended from="35" to="56" max="60" />}>
          <>
            <h2>{ t('New') }</h2>
            <h4>{ t('Large backwall display') }</h4>
            <div><Ref>T801046992</Ref></div>
            { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 430, h: 680 }) }<br />
            { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 430, h: 680 }) }
            <h4>{ t('Includes') }</h4>
            <ul className="include-list">
              <li>{ t('merchandising.displays.n-largeb', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-centralbase', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-centralext', { count: 1 }) }</li>
              <li>{ t('merchandising.displays.n-sidebase', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-sideext', { count: 2 }) }</li>
              <li>{ t('merchandising.displays.n-cush', { count: 60 }) }</li>
              <li>{ t('merchandising.displays.n-cushsup', { count: 35 }) }</li>
              <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-infopsup', { count: 4 }) }</li>
              <li>{ t('merchandising.displays.n-fix', { count: 4 }) }</li>
            </ul>
            <h4>{ t('Optional elements') }</h4>
            <div className="inlines">
              <div className="pl">
                1x { t('Backwall highlighter') } - <Ref>T801047001478</Ref><br />
                { t('merchandising.displays.sizes', { w: 845, d: 490, h: 550 }) }
              </div>
              <div className="pt pb">
                <img className="optional" src={opBackwall} alt=""/>
              </div>
            </div><br/>
            <div className="inlines">
              <div className="pl">
                1x { t('Triangle logo') } - <Ref>T801046999</Ref><br />
                { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }
              </div>
              <div className="pt pb">
                <img className="optional" src={opTriangle} alt=""/>
              </div>
            </div>
          </>
          <div className="grid middle">
            <DisplayOld img={dOldLargeback} title={t('Large backwall')}>
              <div><Ref>T801036718</Ref></div>
              { t('merchandising.displays.sizes', { w: '740-1074', d: '436-585', h: 770 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 36, to: 60 }) }
            </DisplayOld>
            <div className="col-2"></div>
          </div>
        </DisplayEven>
      </div>
      <DisplayOdd img={dNewCushdis} imgContent={<Recommended from="28"/>} id="cushions-display" title={t('Cushions display')}>
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Cushions display') }</h4>
          <div><Ref>T801046993</Ref></div>
          { t('Closed') }: { t('merchandising.displays.sizes', { w: 392, d: 400, h: 122 }) }<br />
          { t('Open') }: { t('merchandising.displays.sizes', { w: 392, d: 122, h: 400 }) }
          <h4>{ t('Includes') }</h4>
          <ul className="include-list">
            <li>{ t('merchandising.displays.n-centralbase', { count: 1 }) }</li>
            <li>{ t('merchandising.displays.n-centralext', { count: 1 }) }</li>
            <li>{ t('merchandising.displays.n-cush', { count: 30 }) }</li>
            <li>{ t('merchandising.displays.n-coverp', { count: 4 }) }</li>
            <li>{ t('merchandising.displays.n-infopsup', { count: 4 }) }</li>
            <li>{ t('merchandising.displays.n-fix', { count: 4 }) }</li>
          </ul>
          <h4>{ t('Optional elements') }</h4>
          <div className="inlines">
            <div className="pl">
              1x { t('Logo coverplate') } - <Ref>T801046998</Ref>
            </div>
            <div className="pt pb">
              <img className="optional" src={opCoverplate} alt=""/>
            </div>
          </div>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldSISLargeInc} title={t('SIS large counter display')}>
            <div><Ref>T801039996</Ref></div>
            { t('merchandising.displays.sizes', { w: 414, d: 488, h: 100 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 30 }) }
          </DisplayOld>
          <DisplayOld img={dOldCushback} title={t('Cushions backwall')}>
            <div><Ref>T801039996</Ref></div>
            { t('merchandising.displays.sizes', { w: 400, d: 112, h: 414 }) }<br/>
            { t('merchandising.displays.n-pieces', { count: 24 }) }
          </DisplayOld>
        </div>
      </DisplayOdd>
      <div className="display even" id="highlighters-display">
        <div className="container">
          <h2 className="main">{ t('Highlighters') }</h2>
          <div className="grid stretch">
            <div className="col-2 bg-dark relative">
              <h2>{ t('New') }</h2>
              <h4>{ t('Tiny highlighter') }</h4>
              <div><Ref>T801046995</Ref></div>
              { t('merchandising.displays.sizes', { w: 240, d: '*', h: 270 }) }<br />
              <h4>{ t('Includes') }</h4>
              <ul className="include-list cols">
                <li>{ t('merchandising.displays.n-tinyvisu', { count: 1 }) }</li>
                <li>{ t('merchandising.displays.n-foot', { count: 2 }) }</li>
                <li>{ t('merchandising.displays.n-cush', { count: 6 }) }</li>
                <li>{ t('merchandising.displays.n-coverp', { count: 9 }) }</li>
                <li>
                  { t('merchandising.displays.n-hwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 85 }) }
                </li>
                <li>
                  { t('merchandising.displays.n-mwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 55 }) }
                </li>
                <li>
                  { t('merchandising.displays.n-lwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 35 }) }
                </li>
              </ul>
            </div>
            <div className="col-2 col-img relative">
              <img className="block" src={dNewTinyHighlighter} alt=""/>
              <Recommended from="3" to="6"/>
            </div>
          </div><br/><br/>
          <div className="grid stretch">
            <div className="col-2 bg-dark relative">
              <h2>{ t('New') }</h2>
              <h4>{ t('Collection highlighter') }</h4>
              <div><Ref>T801046994</Ref></div>
              { t('merchandising.displays.sizes', { w: 370, d: '*', h: 400 }) }<br />
              <h4>{ t('Includes') }</h4>
              <ul className="include-list cols">
                <li>{ t('merchandising.displays.n-squarevisu', { count: 1 }) }</li>
                <li>{ t('merchandising.displays.n-foot', { count: 2 }) }</li>
                <li>{ t('merchandising.displays.n-cush', { count: 9 }) }</li>
                <li>{ t('merchandising.displays.n-coverp', { count: 12 }) }</li>
                <li>
                  { t('merchandising.displays.n-backhigh', { count: 1 }) }
                  - { t('merchandising.displays.sizes', { w: 160, d: 60, h: 171 }) }
                </li>
                <li>
                  { t('merchandising.displays.n-hwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 85 }) }
                </li>
                <li>
                  { t('merchandising.displays.n-mwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 55 }) }
                </li>
                <li>
                  { t('merchandising.displays.n-lwatchstand', { count: 2 }) }
                  - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 35 }) }
                </li>
              </ul>
            </div>
            <div className="col-2 col-img relative">
              <img className="block" src={dNewHighlighter} alt=""/>
              <Recommended from="5" to="9"/>
            </div>
          </div>
          <h2>{ t('Existing') }</h2>
          <div className="grid middle">
            <DisplayOld img={dOldWalldis} title={t('Wall display')}>
              <div><Ref>T801039994</Ref></div>
              { t('merchandising.displays.sizes', { w: 390, d: 203, h: 414 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 4, to: 12 }) }
            </DisplayOld>
            <DisplayOld img={dOldTinyback} title={t('Tiny backwall')}>
              <div><Ref>T801036723</Ref></div>
              { t('merchandising.displays.sizes', { w: 245, d: 245, h: 275 }) }<br/>
              { t('merchandising.displays.n-pieces-interval', { from: 6, to: 10 }) }
            </DisplayOld>
          </div>
        </div>
      </div>
      <DisplayOdd img={dNewWatchstand} imgContent={<Recommended from="6"/>} id="watchstand-set" title={t('Watchstand set')}>
        <>
          <h2>{ t('New') }</h2>
          <h4>{ t('Watchstand set') }</h4>
          <div><Ref>T801046996</Ref></div>

          { t('merchandising.displays.sizes', { w: 240, d: '*', h: 270 }) }<br />
          <h4>{ t('Includes') }</h4>
          <ul className="include-list cols">
            <li>{ t('merchandising.displays.n-cush', { count: 6 }) }</li>
            <li>{ t('merchandising.displays.n-coverp', { count: 9 }) }</li>
            <li>
              { t('merchandising.displays.n-hwatchstand', { count: 2 }) }
              - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 85 }) }
            </li>
            <li>
              { t('merchandising.displays.n-mwatchstand', { count: 2 }) }
              - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 55 }) }
            </li>
            <li>
              { t('merchandising.displays.n-lwatchstand', { count: 2 }) }
              - { t('merchandising.displays.sizes', { w: 50, d: 50, h: 35 }) }
            </li>
          </ul>
        </>
        <div className="grid middle">
          <DisplayOld img={dOldWatchstand} title={t('Watchstand set white')}>
            <div><Ref>T800036918</Ref></div>
            { t('merchandising.displays.n-pieces', { count: 6 }) }
          </DisplayOld>
          <div className="col-2"></div>
        </div>
      </DisplayOdd>
    </div>
  );
}

export default DisplaysPageDisplays;
