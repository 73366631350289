import React, { useContext } from 'react';
import ReactDOMServer from 'react-dom/server'
import AppContext from 'utils/AppContext';
import SoldierImage from 'components/SoldierImage';
import ProductPriceComment from 'components/product/ProductPriceComment';

function StrapTeaserContent(props) {
  const { strap } = props;
  const { size } = props;
  const context = useContext(AppContext);
  return (
    <div className="content">
      <div className="content-inside">
        <SoldierImage src={strap.getSoldierUrl('soldier')} />
        {size === 'full' && (
          <>
            {strap.stock !== null && (
              <div className={`stock stock-${strap.stock}`}></div>
            )}
            {strap.isNovelty && (
              <div className="novelty-badge">new</div>
            )}
            <div className="infos">
              <h3>{strap.name}</h3>
              <div className="sku">{strap.originalSku}</div>
              {!context.hidePrice && strap.price !== null ?
                <div className="price" title={ReactDOMServer.renderToString(ProductPriceComment())}>{strap.price}<sup>*</sup></div> : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default StrapTeaserContent;
