/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gHome from 'assets/g_home.jpg';
import gGoldenrules from 'assets/g_goldenrules.jpg';
import g1do from 'assets/g_1_do.jpg';
import g1dont from 'assets/g_1_dont.jpg';
import g2do1 from 'assets/g_2_do1.jpg';
import g2do2 from 'assets/g_2_do2.jpg';
import g2do3 from 'assets/g_2_do3.jpg';
import g2do4 from 'assets/g_2_do4.jpg';
import g3do1 from 'assets/g_3_do1.jpg';
import g3do2 from 'assets/g_3_do2.jpg';
import g3do3 from 'assets/g_3_do3.jpg';
import g3do4 from 'assets/g_3_do4.jpg';
import g6do1 from 'assets/g_6_do1.jpg';
import g6do2 from 'assets/g_6_do2.jpg';
import g5_1 from 'assets/g_5_1.jpg';
import g5_2 from 'assets/g_5_2.jpg';
import g7_1 from 'assets/g_7_1.jpg';
import g7_2 from 'assets/g_7_2.jpg';
import g8_1 from 'assets/g_8_1.jpg';
import g8_2 from 'assets/g_8_2.jpg';
import getI18nEn from 'i18nEn';
import Breadcrumb from 'components/nav/Breadcrumb';
import MerchandisingMenu from './MerchandisingMenu';
import GuidelinesPageVisuals from './GuidelinesPageVisuals';
import GuidelinesPageRules from './GuidelinesPageRules';
import Warn from './Warn';

function SvgOverlay(props) {
  const { rule } = props;
  if (rule < 5 || rule > 8) return null;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1400 846">
      <defs>
        <style>
          { `
            .a, .b {
              fill: none;
              stroke-miterlimit: 10;
            }

            .a {
              stroke: #fff;
              stroke-width: 6px;
            }

            .b {
              stroke: #ed1c24;
              stroke-width: 3px;
            }

            .c {
              fill: #fff;
              opacity: 0.7;
            }

            .d {
              fill: #ed1c24;
            }
          ` }
        </style>
      </defs>
      { rule === 5 && (
        <g>
          <g>
            <circle className="a" cx="1004.31" cy="743.22" r="25.53"/>
            <circle className="b" cx="1004.31" cy="743.22" r="25.53"/>
          </g>
          <g>
            <circle className="a" cx="1072.04" cy="561.38" r="25.53"/>
            <circle className="b" cx="1072.04" cy="561.38" r="25.53"/>
          </g>
          <g>
            <circle className="a" cx="221.88" cy="586.91" r="25.53"/>
            <circle className="b" cx="221.88" cy="586.91" r="25.53"/>
          </g>
          <path className="c" d="M0,0V846H1400V0ZM221.88,612.45a25.54,25.54,0,1,1,25.53-25.54A25.54,25.54,0,0,1,221.88,612.45Zm643-178.05L616,405.38,612.61,163.7l261.77,10.05Zm139.42,334.35a25.54,25.54,0,1,1,25.53-25.53A25.54,25.54,0,0,1,1004.31,768.75ZM1072,586.91a25.54,25.54,0,1,1,25.53-25.53A25.53,25.53,0,0,1,1072,586.91Z"/>
        </g>
      )}
      { rule === 6 && (
        <g>
          <g>
            <polygon className="a" points="964.8 589.84 1023.4 561.38 1018.38 621.66 964.8 589.84"/>
            <polygon className="b" points="964.8 589.84 1023.4 561.38 1018.38 621.66 964.8 589.84"/>
          </g>
          <g>
            <polygon className="a" points="941.36 671.05 910.38 621.66 843.4 654.31 941.36 671.05"/>
            <polygon className="b" points="941.36 671.05 910.38 621.66 843.4 654.31 941.36 671.05"/>
          </g>
          <g>
            <polygon className="a" points="854.29 591.52 862.66 537.1 914.56 549.66 854.29 591.52"/>
            <polygon className="b" points="854.29 591.52 862.66 537.1 914.56 549.66 854.29 591.52"/>
          </g>
        </g>
      )}
      { rule === 7 && (
        <g>
          <path
            className="c"
            d="M1398.9,0H1.1C0.5,0,0,0.5,0,1.1v843.8c0,0.6,0.5,1.1,1.1,1.1h1397.8c0.6,0,1.1-0.5,1.1-1.1V1.1
            C1400,0.5,1399.5,0,1398.9,0z M338.9,606.3l-5.3,3.8c-0.3,0.2-0.6,0.2-0.9,0.2l-39-8c-0.6-0.1-1-0.7-0.9-1.3l1.5-8.9
            c0.1-0.6,0.7-1,1.3-0.9l38.6,7.1c0.3,0.1,0.5,0.2,0.7,0.5l4.2,6C339.5,605.3,339.4,605.9,338.9,606.3z M469,560
            c-7.2,3.6-14.8,15.5-15.7,16.9c-0.1,0.1-0.1,0.2-0.2,0.2l-28.2,24.2c-0.3,0.2-0.6,0.3-1,0.2l-32.9-7.2c-0.9-0.2-1.2-1.3-0.5-1.9
            l48.3-39.8c0.3-0.2,0.6-0.3,0.9-0.2l28.9,5.5c0.5,0.1,0.8,0.5,0.9,0.9C469.7,559.4,469.4,559.8,469,560z M731.9,608l-36,45.2
            c-0.3,0.3-0.7,0.5-1.1,0.4l-40.5-9c-0.8-0.2-1.1-1.2-0.6-1.8l38.6-45.3c0.3-0.3,0.6-0.4,1-0.4l34,5.9c0.2,0,0.4,0.1,0.5,0.2l3.9,3.1
            C732.2,606.8,732.3,607.5,731.9,608z M830.4,639.1l-2.6,10.6c-0.1,0.6-0.7,0.9-1.3,0.8l-20.1-4.4c-0.5-0.1-0.9-0.6-0.9-1.2l0.8-10.1
            c0-0.7,0.6-1.1,1.3-1l22,3.9C830.1,637.8,830.5,638.5,830.4,639.1z"/>
          <polygon className="d" points="322.99 556.5 322.99 532.39 309.39 532.39 309.39 556.5 296.67 556.5 316.19 576.03 335.72 556.5 322.99 556.5"/>
          <polygon className="d" points="824.81 603.02 824.81 578.91 811.21 578.91 811.21 603.02 798.48 603.02 818.01 622.54 837.53 603.02 824.81 603.02"/>
        </g>
      )}
      { rule === 8 && (
        <path className="c" d="M0,0V846H1400V0ZM864.89,434.4,616,405.38,612.61,163.7l261.77,10.05Z"/>
      )}
    </svg>
  );
}

function Rule(props) {
  const { rule, extra } = props;
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  return (
    <div className="grid">
      <div className="col-2 bg-dark">
        <h4>{ t('merchandising.guidelines.goldenrule', { n: rule }) }</h4>
        <h3>{ t('merchandising.guidelines.guideline-' + rule + '-title') }</h3>
        <ul
          className="rules-list"
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: t('merchandising.guidelines.guideline-' + rule) }}>
        </ul>
        { extra }
      </div>
      { props.children }
    </div>
  );
}

function Rules() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const [activeRule, setActiveRule] = useState(1);
  const [clicked, setClicked] = useState(false);
  const handleClick = (e) => {
    setClicked(true);
    setActiveRule(parseInt(e.target.innerText, 10));
  };

  const buttons = [];
  for (let i = 1; i <= 9; i++) {
    const classes = ['number'];
    if (i === activeRule) classes.push('active');
    buttons.push(<button key={i} type="button" className={classes.join(' ')} onClick={handleClick}>{ i }</button>);
  }
  const rule8Extra = (
    <Warn>{ t('We ask you to print locally visuals on cardboard 2mm instead of forex to be more eco-friendly.') }</Warn>
  );

  useEffect(() => {
    if (!clicked) {
      const inter = setInterval(() => {
        setActiveRule(oldRule => (oldRule === 9 ? 1 : oldRule + 1));
      }, 2000);
      return () => {
        clearInterval(inter);
      }
    }
    return () => {};
  }, [clicked]);

  return (
    <div className="container">
      <h2 className="main">{ t('Golden rules') }</h2>
      <div className="rules-main-img trailer-1">
        <img src={gGoldenrules} alt=""/>
        <SvgOverlay rule={activeRule} />
        <div className="buttons">
          { buttons }
        </div>
      </div>
      <div id="text-p1">
        { activeRule === 1 && (
          <Rule rule="1">
            <div className="col-1">
              <img src={g1dont} alt=""/>
            </div>
            <div className="col-1">
              <img src={g1do} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 2 && (
          <Rule rule="2">
            <div className="col-1">
              <img src={g2do1} alt="" className="trailer-1"/>
              <img src={g2do3} alt=""/>
            </div>
            <div className="col-1">
              <img src={g2do2} alt="" className="trailer-1"/>
              <img src={g2do4} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 3 && (
          <Rule rule={activeRule}>
            <div className="col-1">
              <img src={g3do1} alt=""/>
            </div>
            <div className="col-1">
              <img src={g3do3} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 4 && (
          <Rule rule={activeRule}>
            <div className="col-1">
              <img src={g3do2} alt=""/>
            </div>
            <div className="col-1">
              <img src={g3do4} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 5 && (
          <Rule rule={activeRule}>
            <div className="col-1">
              <img src={g5_1} alt=""/>
            </div>
            <div className="col-1">
              <img src={g5_2} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 6 && (
          <Rule rule={activeRule}>
            <div className="col-2">
              <img src={g6do1} alt="" className="trailer-1"/>
              <img src={g6do2} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 7 && (
          <Rule rule={activeRule}>
            <div className="col-1">
              <img src={g7_1} alt=""/>
            </div>
            <div className="col-1">
              <img src={g7_2} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 8 && (
          <Rule rule={activeRule} extra={rule8Extra}>
            <div className="col-2">
              <img src={g8_1} alt="" className="trailer-1"/>
              <img src={g8_2} alt=""/>
            </div>
          </Rule>
        )}
        { activeRule === 9 && (
          <Rule rule={activeRule}>
            <div className="col-2 lessismore">
              <span>{ t('Less is more') }</span>
            </div>
          </Rule>
        )}

      </div>
    </div>
  );
}

function GuidelinesPage() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });

  const menu = [
    { id: 'content-rules', text: t('Golden rules') },
    { id: 'content-visuals', text: t('Visuals - sizes') },
    { id: 'rules-40-50', text: t('Rules 40-50 watches') },
    { id: 'rules-70-90', text: t('Rules 70-90 watches') },
    { id: 'rules-100-120', text: t('Rules 100-120 watches') },
    { id: 'rule-highlighter', text: t('Rule highlighter') },
    { id: 'safe-improvement', text: t('Safe improvement possibilities') },
    { id: 'boutique-sis', text: t('Boutique & SIS safe') },
  ];

  const breadcrumb = [
    { path: 'merchandising', name: t('Merchandising') },
    { path: 'merchandising/guidelines', name: t('Merchandising Guidelines') }
  ];
  return (
    <div className="merchandising-guidelines contents-page">
      <div className="container">
        <Breadcrumb menu={breadcrumb} tOptions={{ i18n: getI18nEn() }} />
      </div>
      <div className="content content-head content-head-basic">
        <div className="container relative">
          <img src={gHome} alt="" />
          <div className="grid above">
            <div className="col-2">
            </div>
            <div className="col-2">
              <h1>{ t('Merchandising Guidelines') }</h1>
            </div>
          </div>
        </div>
        <div className="band"></div>
      </div>
      <MerchandisingMenu elements={menu}/>
      <div className="content content-grules" id="content-rules">
        <Rules />
      </div>
      <GuidelinesPageVisuals />
      <GuidelinesPageRules />
    </div>
  );
}

export default GuidelinesPage;
