import React, { useState, useEffect } from 'react';
import MessageBox from 'components/MessageBox';
import * as serviceWorker from 'serviceWorkerRegistration';

// t('A new version is available!')
// t('Reload')
const texts = {
  en: {
    txt: 'A new version is available!',
    reload: 'Reload',
  },
  fr: {
    txt: 'Une nouvelle version est disponible !',
    reload: 'Rafraîchir',
  },
  de: {
    txt: 'Eine neue Version ist verfügbar!',
    reload: 'Neu laden'
  },
  es: {
    txt: '¡Nueva versión disponible!',
    reload: 'Volver a cargar'
  },
  it: {
    txt: 'È disponibile una nuova versione!',
    reload: 'Aggiorna'
  },
  ru: {
    txt: 'Доступна новая версия!',
    reload: 'Перезагрузить'
  },
  zh: {
    txt: '新版本已推出！',
    reload: '重新加载'
  },
  zht: {
    txt: '新版已推出！',
    reload: '重新載入'
  },
};

function ServiceWorkerLoader() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const ln = navigator.language.substring(0, 2);
  const textsI18n = (ln in texts) ? texts[ln] : texts.en;

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };

  if (!showReload) return null;
  return (
    <MessageBox type="warning" context="full" onClick={reloadPage}>
      { textsI18n.txt }
      <hr/>
      <button className="btn" type="button" onClick={reloadPage}>{ textsI18n.reload }</button>
    </MessageBox>
  );
}

export default ServiceWorkerLoader;
