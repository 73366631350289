/* eslint no-return-assign: "error" */
import React from 'react';

class Dropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };

    this.onExpand = this.onExpand.bind(this);
    this.closeDropdown = this.closeDropdown.bind(this);
  }

  catchEvents(e) {
    e.stopPropagation();
  }

  componentDidMount() {
    this.node.addEventListener('close-dropdown', this.closeDropdown);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown);
    this.node.removeEventListener('close-dropdown', this.closeDropdown);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.opened !== prevState.opened &&
      this.state.opened &&
      ('open' in this.props)
    ) {
      this.props.open();
    }
  }

  onExpand(e) {
    e.stopPropagation();
    this.setState(prevState => ({ opened: !prevState.opened }), () => {
      if (this.state.opened) {
        window.addEventListener('click', this.closeDropdown, { once: true });
      }
    });
  }

  closeDropdown() {
    this.setState({
      opened: false
    });
  }

  render() {
    /* eslint-disable jsx-a11y/no-static-element-interactions,
                      jsx-a11y/click-events-have-key-events */
    const classes = ['dropdown'];
    const { children, title, name, state } = this.props;
    if (state) classes.push(state);
    if (name) classes.push('dropdown-' + name);
    if (this.state.opened) classes.push('opened');
    return (
      <div ref={node => (this.node = node)} className={classes.join(' ')} onClick={this.catchEvents}>
        <button type="button" onClick={this.onExpand}>{title}</button>
        <div className="dropdown-content">
          {children}
        </div>
      </div>
    );
  }
}

export default Dropdown;
