/* eslint-disable react/no-unused-state, react/no-danger */
/*
 * Additional translations:
 * t('stock.green')
 * t('stock.grey')
 * t('stock.yellow')
 */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import Spinner from 'components/Spinner';
import StrapsTechnicalSpecs from 'components/product/StrapsTechnichalSpecs';
import ProductPrice from 'components/product/ProductPrice';
import ProductImages from 'components/product/ProductImages';
import Strap from 'models/Strap';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import AppContext from 'utils/AppContext';
import SeparatedTitle from 'components/SeparatedTitle';
import Barcode from 'components/Barcode';
import shadowlightbig from 'assets/shadowlightbig.jpg';
import SoldierImage from 'components/SoldierImage';
import ProductNovelty from 'components/product/ProductNovelty';
import Breadcrumb from 'components/nav/Breadcrumb';
import BreadcrumbStrapSku from 'components/nav/BreadcrumbStrapSku';
import SampleToOrder from 'components/product/SampleToOrder';

class StrapDetailPage extends React.Component {

  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(this.props.location.search);

    this.state = {
      isLoaded: false,
      data: null,
      lang: i18n.language,
      other_family_products: null,
      context: searchParams.get('context'),
      contextData: searchParams.get('data'),
    };
  }

  load() {
    const { country, token } = this.context;
    this.product = new Strap(null, country, token);
    this.product.sku = this.props.match.params.sku;
    this.product.lang = this.state.lang;
    this.product.fetch(this);
  }

  componentDidUpdate(prevProps, prevState) {

    if (
      this.props.match.params.sku !== prevProps.match.params.sku ||
      this.props.location.search !== prevProps.location.search
    ) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const newState = {
        context: searchParams.get('context'),
        contextData: searchParams.get('data')
      };
      if (this.props.match.params.sku !== prevProps.match.params.sku) {
        newState.isLoaded = false;
        this.setState(newState);
        this.load();
      }
      else {
        this.setState(newState);
      }
    }
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load();
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  render() {
    const { isLoaded } = this.state;
    const { t } = this.props;

    if (!isLoaded || !this.product || !this.product._data) {
      return <Spinner/>;
    } else {
      return (
        <div className={`watch-full watch-full-strap strap-${this.product.sku}`}>

          <div className="head">
            <div className="container">
              {this.state.context === 'watch' && (
                <BreadcrumbStrapSku sku={this.state.contextData}>
                  <Link to={'/straps-fromwatch/' + this.state.contextData}>
                    {t('Straps for «{{ search }}»', { search: this.state.contextData })}
                  </Link>
                </BreadcrumbStrapSku>
              )}
              {this.state.context === 'search' && (
                <Breadcrumb>
                  <Link to="/straps-page">
                    {t('Straps')}
                  </Link>
                  { this.state.contextData ? (
                    <Link to={'/straps/' + this.state.contextData}>
                      {t('Search a compatible strap for «{{ search }}»', { search: this.state.contextData })}
                    </Link>
                  ) : (
                    <Link to={'/straps'}>
                      {t('Search straps')}
                    </Link>
                  ) }

                </Breadcrumb>
              )}
            </div>
            <div className="container">
              <div className="infos">
                <h1><SeparatedTitle value={this.product.name} /></h1>
                <div className="product-announcements">
                  <ProductNovelty product={this.product}/>
                  <SampleToOrder product={this.product}/>
                </div>
                <div className="sku">{this.product.originalSku}</div>
                <ProductPrice product={this.product}/>
                {this.product.stock !== null && (
                  <div className="stock-container">
                    <div className={`stock stock-${this.product.stock}`}></div>
                    <span className="stock-text">{t('stock.' + this.product.stock)}</span>
                  </div>
                )}

              </div>
              <div className="visual">
                <div className="img">
                  {this.product.soldier !== null && (
                    <img className="img-shadow" src={shadowlightbig} alt="" />
                  )}
                  <SoldierImage src={this.product.getSoldierUrl('soldier_full_bracelet')} />
                  <Barcode code={this.product._data.ean}/>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <StrapsTechnicalSpecs product={this.product}/>
            <ProductImages product={this.product}/>
          </div>
        </div>
      )
    }
  }
}
StrapDetailPage.contextType = AppContext;

export default withTranslation()(StrapDetailPage);
