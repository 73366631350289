import React from 'react';
import { useTranslation } from 'react-i18next';
import iHome from 'assets/i_home.jpg';
import iSpecial1 from 'assets/i_special_1.jpg';
import iSpecial1Ex1 from 'assets/i_special_1_ex_1.svg';
import iSpecial1Ex2 from 'assets/i_special_1_ex_2.svg';
import iSpecial1Ex3 from 'assets/i_special_1_ex_3.svg';
import iSpecial2 from 'assets/i_special_2.jpg';
import iSpecial2Ex1 from 'assets/i_special_2_ex_1.jpg';
import iSpecial2Ex2 from 'assets/i_special_2_ex_2.jpg';
import iSpecial2Ex3 from 'assets/i_special_2_ex_3.jpg';
import iSpecial2Ex4 from 'assets/i_special_2_ex_4.jpg';
import iSpecial3 from 'assets/i_special_3.jpg';
import iSpecial3Ex1 from 'assets/i_special_3_ex_1.svg';
import iSpecial4 from 'assets/i_special_4.jpg';
import iSpecial4Ex1 from 'assets/i_special_4_ex_1.jpg';
import iSpecial4Ex2 from 'assets/i_special_4_ex_2.jpg';
import iSpecial4Ex3 from 'assets/i_special_4_ex_3.jpg';
import getI18nEn from 'i18nEn';
import Breadcrumb from 'components/nav/Breadcrumb';
import Warn from './Warn';
import Ref from './Ref';
import MerchandisingMenu from './MerchandisingMenu';

function ImplementationPage() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });

  const breadcrumb = [
    { path: 'merchandising', name: t('Merchandising') },
    { path: 'merchandising/implementation', name: t('Implementation And Case Studies') }
  ];
  return (
    <div className="implementation-case contents-page">
      <div className="container">
        <Breadcrumb menu={breadcrumb} tOptions={{ i18n: getI18nEn() }} />
      </div>
      <div className="content content-head content-head-basic">
        <div className="container relative">
          <img src={iHome} alt="" />
          <div className="grid above">
            <div className="col-2">
            </div>
            <div className="col-2">
              <h1>{ t('Implementation And Case Studies') }</h1>
            </div>
          </div>
        </div>
        <div className="band"></div>
      </div>
      <MerchandisingMenu/>
      <div className="content dark wide content-layout">
        <div className="container">
          <h2 className="main">{ t('Special solutions') }</h2>
          <div className="grid above">
            <div className="col-2">
              <img src={iSpecial1} alt=""/>
              <Warn>{ t('Avoid having too many logos') }</Warn>
            </div>
            <div className="col-2">
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial1Ex1} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Tiny highlighter') } <Ref>T801046995</Ref></li>
                    <li>{ t('Square visual') } <Ref>T801046994</Ref></li>
                    <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                    <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                    <li>{ t('Triangle logo') } <Ref>T801046999</Ref></li>
                  </ul>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial1Ex2} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Cushion display') } <Ref>T801046993</Ref></li>
                    <li>{ t('Logo cover plate') } <Ref>T801046998</Ref></li>
                  </ul>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial1Ex3} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                    <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                    <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content wide content-layout">
        <div className="container">
          <div className="grid above">
            <div className="col-2">
              <img src={iSpecial2} alt=""/>
              <Warn>{ t('Avoid having too many logos') }</Warn>
            </div>
            <div className="col-2">
              <h2>{ t('Full height ambiance visual') }</h2>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial2Ex1} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Tiny highlighter') } <Ref>T801046995</Ref></li>
                    <li>{ t('Large visual') } <Ref>T801046994</Ref></li>
                    <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                  </ul>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial2Ex2} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                    <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                    <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                  </ul>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial2Ex3} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Large visual') } <Ref>T801046994</Ref></li>
                    <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                    <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                  </ul>
                </div>
              </div>
              <div className="grid middle">
                <div className="col-2">
                  <img src={iSpecial2Ex4} alt=""/>
                </div>
                <div className="col-2">
                  <ul className="include-list">
                    <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                    <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                    <li>{ t('Triangle logo') } <Ref>T801046999</Ref></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content dark wide content-layout">
        <div className="container">
          <div className="grid middle trailer-1">
            <div className="col-2">
              <img src={iSpecial3} alt=""/>
            </div>
            <div className="col-2">
              <img src={iSpecial3Ex1} alt=""/>
            </div>
          </div>
          <div className="grid">
            <div className="col-2">
              <Warn>{ t('Avoid having too many logos') }</Warn>
            </div>
            <div className="col-2">
              <ul className="include-list">
                <li>{ t('Tiny highlighter') } <Ref>T801046995</Ref></li>
                <li>{ t('Large visual') } <Ref>T801046994</Ref></li>
                <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                <li>{ t('Triangle logo') } <Ref>T801046999</Ref></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="content wide content-layout">
        <div className="container">
          <div className="grid above">
            <div className="col-2">
              <img src={iSpecial4} alt=""/>
              <Warn>{ t('Avoid having too many logos') }</Warn>
            </div>
            <div className="col-2">
              <h2>{ t('Full height ambiance visual') }</h2>
              <img src={iSpecial4Ex1} alt=""/>
              <ul className="include-list cols">
                <li>{ t('Backwall highlighter') } <Ref>T801047001</Ref></li>
                <li>{ t('Extension') } <Ref>T801046987</Ref></li>
                <li>{ t('Watchstand set') } <Ref>T801046996</Ref></li>
                <li>{ t('Triangle logo') } <Ref>T801046999</Ref></li>
                <li>{ t('Tiny highlighter') } <Ref>T801046995</Ref></li>
                <li>{ t('Square visual') } <Ref>T801046994</Ref></li>
                <li>{ t('Large visual') } <Ref>T801046994</Ref></li>
              </ul>
              <img src={iSpecial4Ex2} alt=""/>
              <ul className="include-list cols">
                <li>{ t('Counter') } <Ref>T801046986</Ref></li>
                <li>{ t('Quality plate') } <Ref>T800047212</Ref></li>
              </ul>
              <img src={iSpecial4Ex3} alt=""/>
              <ul className="include-list">
                <li>{ t('Counter') } <Ref>T801046986</Ref></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImplementationPage;
