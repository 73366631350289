import React from 'react';
import { useTranslation } from 'react-i18next'
import gRules1 from 'assets/g_rules_1.jpg';
import gRules2 from 'assets/g_rules_2.jpg';
import gRules3 from 'assets/g_rules_3.jpg';
import gRules4 from 'assets/g_rules_4.jpg';
import gRules5 from 'assets/g_rules_5.jpg';
import gRules6 from 'assets/g_rules_6.jpg';
import gRules7 from 'assets/g_rules_7.jpg';
import gRules8 from 'assets/g_rules_8.jpg';
import gSafe1 from 'assets/g_safe_1.jpg';
import gSafe2 from 'assets/g_safe2.jpg';
import gSafe3 from 'assets/g_safe3.jpg';
import getI18nEn from 'i18nEn';
import GuidelinesPageRule from './GuidelinesPageRule';
import Warn from './Warn';

function GuidelinesPageRules() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });

  const r1paths = {
    salmon: <rect className="cls cls-1" x="158.61" y="255.29" width="136.31" height="116.95"/>,
    yellow: (
      <>
        <rect className="cls cls-2" x="348.17" y="110.24" width="282.42" height="255.77"/>
        <rect className="cls cls-2" x="320.86" y="371.59" width="343.63" height="51.15"/>
      </>
    ),
    cyan: (
      <>
        <polygon className="cls cls-3" points="296.61 551.07 693.5 551.07 666.17 428.42 310.87 428.42 296.61 551.07"/>
        <polygon className="cls cls-3" points="130.24 426.74 116.46 446.97 306.59 447.92 309.1 426.74 130.24 426.74"/>
      </>
    ),
    blue: <polygon className="cls cls-4" points="113.3 467.89 69.88 579.11 291.38 579.72 301.83 467.28 113.3 467.89"/>,
    red: (
      <>
        <rect className="cls cls-5" x="678.54" y="255.29" width="144" height="110.72"/>
        <polygon className="cls cls-5" points="702.22 576.66 916.84 574.13 849.4 421.04 668.3 422.73 702.22 576.66"/>
      </>
    )
  };
  const r2paths = {
    salmon: null,
    yellow: <polygon className="cls cls-2" points="413 156.56 371.75 205.08 720.01 249.9 753.18 194.75 413 156.56"/>,
    cyan: (
      <>
        <polygon className="cls cls-3" points="208.02 352.62 608.2 427.48 719.82 271.9 359.27 219.71 208.02 352.62"/>
        <polygon className="cls cls-3" points="203.8 188.26 357.6 210.81 394.52 172.47 246.6 151.83 203.8 188.26"/>
      </>
    ),
    blue: <polygon className="cls cls-4" points="26.73 342.44 173.39 371.98 350.61 217.81 197.84 194.75 26.73 342.44"/>,
    red: <polygon className="cls cls-5" points="597.09 457.02 890.42 510.49 971.39 287.95 739.68 256.88 597.09 457.02"/>
  };
  const r3paths = {
    salmon: null,
    yellow: (
      <>
        <polygon className="cls cls-2" points="198.4 111.7 163.05 111.97 138.29 460.35 177.3 464.47 198.4 111.7"/>
        <polygon className="cls cls-2" points="340.79 111.7 345.98 468.96 504.17 492.85 528.38 109.87 340.79 111.7"/>
      </>
    ),
    cyan: (
      <>
        <polygon className="cls cls-3" points="301.37 479.72 180.63 465.16 201.79 111.7 324.08 111.7 301.37 479.72"/>
        <polygon className="cls cls-3" points="532.01 108.86 506.1 516.17 668.82 540.01 692.82 106.89 532.01 108.86"/>
      </>
    ),
    blue: <polygon className="cls cls-4" points="31.94 445.18 134.97 459.81 159.33 111.97 58.37 113.53 31.94 445.18"/>,
    red: <polygon className="cls cls-5" points="672.45 539.49 921.7 576.29 949.22 105.38 696.54 106.89 672.45 539.49"/>
  };
  const r4paths = {
    salmon: <rect className="cls cls-1" x="175.15" y="177.53" width="86.01" height="55.39"/>,
    yellow: <polygon className="cls cls-2" points="264.96 246.59 234.11 275.52 463.55 305.65 483.79 271.01 264.96 246.59"/>,
    cyan: (
      <>
        <polygon
          className="cls cls-3"
          points="139 367.09 384.5 414 384.71 433.93 557.38 465.62 619.71 291.26 486.64 271.75 465.24 308.52 231.64 278.23 139 367.09"/>
        <rect className="cls cls-3" x="500.3" y="206.22" width="86.01" height="59.82"/>
      </>
    ),
    blue: <polygon className="cls cls-4" points="27.5 359.7 123.07 377.79 243.75 260.51 160.53 254.11 27.5 359.7"/>,
    red: <polygon className="cls cls-5" points="562.26 527.1 867 582.41 989 411.06 723.3 383.95 562.26 527.1"/>
  };
  const r5paths = {
    salmon: <rect className="cls cls-1" x="552.81" y="171.61" width="84.02" height="84.68"/>,
    yellow: <rect className="cls cls-2" x="206.67" y="66.16" width="192.81" height="482.31"/>,
    cyan: (
      <>
        <rect className="cls cls-3" x="526.57" y="366.91" width="305.45" height="74.06"/>
        <rect className="cls cls-3" x="640.67" y="171.61" width="45.9" height="84.68"/>
      </>
    ),
    blue: <rect className="cls cls-4" x="691.78" y="171.61" width="34.37" height="84.68"/>,
    red: <rect className="cls cls-5" x="552.81" y="265.8" width="174.13" height="82.86"/>
  };
  const r6paths = {
    salmon: null,
    yellow: <rect className="cls cls-2" x="183" y="20" width="606" height="281"/>,
    cyan: null,
    blue: null,
    red: <rect className="cls cls-5" x="183" y="350" width="606" height="281"/>
  };
  const r7paths = {
    salmon: null,
    yellow: <rect className="cls cls-2" x="150" y="40" width="691" height="580"/>,
    cyan: null,
    blue: null,
    red: null
  };
  const r8paths = {
    salmon: <rect className="cls cls-1" x="379.16" y="462.14" width="108.72" height="179.42"/>,
    yellow: (
      <>
        <rect className="cls cls-2" x="510.97" y="23.95" width="299.99" height="297.47"/>
        <rect className="cls cls-2" x="273.18" y="40.12" width="140.98" height="104.63"/>
        <rect className="cls cls-2" x="273.62" y="353.13" width="210.47" height="98.99"/>
      </>
    ),
    cyan: (
      <>
        <rect className="cls cls-3" x="193.26" y="23.18" width="293.07" height="299.02"/>
        <rect className="cls cls-3" x="193.26" y="462.92" width="179.61" height="178.65"/>
      </>
    ),
    blue: <rect className="cls cls-4" x="192.48" y="349.36" width="294.63" height="106.18"/>,
    red: <rect className="cls cls-5" x="510.97" y="348.59" width="300.77" height="292.98"/>
  };

  return (
    <>
      <div id="rules-40-50" className="leader-in-2">
        <GuidelinesPageRule title={t('Rules 40-50 watches')} paths={r1paths} image={gRules1} />
        <GuidelinesPageRule paths={r2paths} image={gRules2} layout="even" />
        <GuidelinesPageRule paths={r3paths} image={gRules3} />
      </div>
      <GuidelinesPageRule
        addClass="leader-in-2"
        layout="even"
        id="rules-70-90"
        title={t('Rules 70-90 watches')}
        paths={r4paths}
        image={gRules4}>
        <Warn>{ t('If there is a counter and a backwall, place T-Classic in the counter') }</Warn>
      </GuidelinesPageRule>
      <div id="rules-100-120" className="leader-in-2">
        <GuidelinesPageRule layout="odd" title={t('Rules 100-120 watches')} paths={r5paths} image={gRules5}/>
        <GuidelinesPageRule layout="even" paths={r6paths} image={gRules6}/>
      </div>
      <GuidelinesPageRule
        addClass="leader-in-2"
        layout="odd"
        id="rule-highlighter"
        title={t('Rule highlighter')}
        paths={r7paths}
        image={gRules7}/>
      <div className="content content-various" id="safe-improvement">
        <div className="container">

          <div className="grid">
            <div className="col-2">
              <img src={gSafe1} alt=""/>
              <ul
                className="rules-list"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: t('merchandising.guidelines.safe-improv-1') }}>
              </ul>
              <img src={gSafe3} alt=""/>
              <ul
                className="rules-list"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: t('merchandising.guidelines.safe-improv-3') }}>
              </ul>
            </div>
            <div className="col-2">
              <h2 className="main">{ t('Safe improvement possibilities') }</h2>
              <img src={gSafe2} alt=""/>
              <ul
                className="rules-list"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: t('merchandising.guidelines.safe-improv-2') }}>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <GuidelinesPageRule
        addClass="leader-in-2"
        layout="odd"
        id="boutique-sis"
        title={t('Boutique & SIS safe')}
        paths={r8paths}
        image={gRules8}/>
    </>
  );
}

export default GuidelinesPageRules;
