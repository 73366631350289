import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

function Breadcrumb(props) {
  let { menu } = props;
  const { t } = useTranslation(null, props.tOptions ? props.tOptions : {});
  const [opened, setOpened] = useState(false);
  if (!menu && !props.children) return null;
  menu = menu || [];

  const navClass = 'breadcrumb ' + (opened ? 'opened' : 'closed'); 
  return (
    <nav className={navClass}>
      <div className="breadcrumb-links">
        <Link to="/">{t('Home')}</Link>
        {menu.map(item => (
          ('path' in item) ? (
            <Link to={'/' + item.path} key={item.path}>{item.name}</Link>
          ) : (
            <Link to={'/collection/' + item.pk} key={item.pk}>{item.resource_name}</Link>
          )
        ))}
        {props.children}
      </div>
      <button className="see-more" type="button" onClick={() => setOpened(!opened)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
          <path d="M0,8.75L1.2,10,6,5,1.2,0,0,1.25,3.6,5Z"></path>
        </svg>
      </button>
    </nav>
  )
}

export default Breadcrumb;