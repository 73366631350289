function month_utils(date, language) {

  let locale = language;
  // use the right chinese locale.
  if (language === 'zh') {
    locale = 'zh-cn';
  } else if (language === 'zht') {
    locale = 'zh-hk';
  }
  return date.toLocaleString(locale, { month: 'long', year: 'numeric' });
}

export default month_utils;
