import React from 'react';
import StrapsFilters from 'components/filters/StrapsFilters';
import StrapsSort from 'components/filters/StrapsSort';
import FilterButtons from 'components/filters/FilterButtons';
  
function StrapsFilterNav(props) {  
  if (props.meta.count < 1) {
    return null;
  }

  return (
    <nav className="filters-nav grid">
      <div className="col-3 s-col-1">
        <div className="filters-container">
          <StrapsFilters meta={props.meta} productsPage={props.productsPage} />
        </div>
      </div>
      <div className="s-col-1">
        <StrapsSort 
          productsPage={props.productsPage} />
      </div>
      <FilterButtons meta={props.meta} productsPage={props.productsPage} />
    </nav>    
  );
}

export default StrapsFilterNav;