
/* eslint-disable-next-line no-unused-vars */
const transforms = {
  soldier: '?io=transform:fit,height:300,width:300',
  soldier_full: '?io=transform:fit,height:700,width:700',
  soldier_full_bracelet: '?io=transform:fit,height:700,width:700',
  detail1: '?io=transform:fill,width:652,height:406,gravity:center',
  detail2: '?io=transform:fill,width:652,height:406,gravity:center',
  detail3: '?io=transform:fill,width:652,height:406,gravity:center',
  caseback: '?io=transform:fit,height:300,width:300',
  profil: '?io=transform:fit,height:300,width:300',
  wrist: '?io=transform:fill,height:406,width:313',
  superluminova: '?io=transform:fill,height:406,width:313',
  box: '?io=transform:fit,height:300,width:300',
  set: '?io=transform:fit,height:300,width:300',
  openclosed: '?io=transform:fit,height:300,width:300',
  open: '?io=transform:fit,height:300,width:300',
  closed: '?io=transform:fit,height:300,width:300',
  zoom: '?io=transform:fill,height:406,width:313',
  lugs: '?io=transform:fit,height:300,width:300'
};

function imgGetUrl(type, url) {
  const size = ['detail1', 'detail2', 'detail3'].includes(type) ? 2 : 1;
  if (!url) {
    return {
      name: null,
      url: null,
      size: size,
    }
  }
  return {
    name: 'img-' + type,
    url: url,
    // url: url + ((type in transforms) ? transforms[type] : ''),
    size: size,
  };
}

export default imgGetUrl;
