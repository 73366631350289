import Model from 'models/Model';
import i18n from 'i18next';
import ReactGA from 'react-ga';
import Storage from 'utils/Storage';

class Context extends Model {
  constructor(appContext) {
    super('api/context');
    this.setGetParam('token', appContext.token);
    this.setGetParam('lang', i18n.language);
    this.appContext = appContext;
  }

  set token(token) {
    if (this.params && 'token' in this.params) {
      this.params.token = token;
    }
    this._token = token;
  }

  get token() {
    return this._token;
  }

  get user() {
    return this.data.user;
  }

  get languages() {
    return this.data.languages;
  }

  get countries() {
    return this.data.countries;
  }

  getLanguage() {
    let lang = Storage.getItem('language');
    if (lang in this.languages) {
      return lang
    }
    lang = this.user.lang;
    if (lang in this.languages) {
      return lang;
    }
    // The backend will respond with the first valid language in browser
    // languages.
    lang= this.response_headers.get('content-language');
    if (lang in this.languages) {
      return lang;
    }
    return 'en';
  }

  onFetch() {
    this.appContext.updateLanguages(this.languages);
    this.appContext.updateCountries(this.countries);
    this.appContext.setUser(this.user);
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: this.user.username
      }
    });

    let country = Storage.getItem('country');
    if (!country || !(country in this.countries)) {
      [country] = Object.keys(this.countries);
    }
    this.appContext.setCountry(country);

    const lang = this.getLanguage();
    i18n.changeLanguage(lang);
    ReactGA.set({ dimension3: lang });
    window.document.documentElement.lang = lang;
  }
}

export default Context;
