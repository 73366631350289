import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import Menu from 'components/nav/Menu'
import SearchDropdown from 'components/nav/SearchDropdown'
import logo from 'logo.svg'
import LanguageSwitcher from 'components/nav/LanguageSwitcher'
import CountrySwitcher from 'components/nav/CountrySwitcher'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import AppContext from 'utils/AppContext'

function NavBar(props) {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  return (
    <nav className="navbar">
      <div className="container">
        <div className="menu-icons">
          <Menu/>
          <Link to="/"><img className="logo" src={logo} alt="TISSOT" /></Link>
          <div className="titles">
            <h1>{t('Sales Manual')}</h1>
            <h2>{context.countries[context.country].name[i18n.language]}</h2>
          </div>
        </div>
        <div className="selects">
          <LanguageSwitcher/>
          <CountrySwitcher/>
          <SearchDropdown/>
        </div>
      </div>
      <div className="subtitle">
        <h2>{context.countries[context.country].resource_name}</h2>
      </div>
    </nav>
  );
}

export default NavBar;
