import React from 'react';
import AppContext from 'utils/AppContext';

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeId: this.props.productsPage.getActiveFilterId(this.props.id)
    };
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(id) {
    this.setState(
      (prevState) => ({ activeId: (prevState.activeId === id ? null : id) }),
      () => this.props.productsPage.setFilter(this.props.id, this.state.activeId)
    );

  }

  render() {
    const { filter, id } = this.props;
    if (filter.values.length > 1 || this.state.activeId) {
      return (
        <div className="filter-filter">
          {id && (
            <h3>{filter.label}</h3>
          )}
          {filter.values.map(filter => (
            <button
              className={`btn-filter${(filter.pk === this.state.activeId) ? ' active' : ''}`}
              key={filter.pk}
              type="button"
              onClick={() => this.changeValue(filter.pk)}>
              {filter.label}
            </button>
          ))}
        </div>
      );
    }
    return null
  }
}
Filter.contextType = AppContext;

export default Filter;
