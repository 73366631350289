import React from 'react';
import JsBarcode from 'jsbarcode';

class BarCode extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  generateCode() {
    try {
      JsBarcode('.barcode', this.props.code, {
        format: 'EAN13',
        background: 'transparent',
        height: 40
      });
    } catch (e) {
      this.setState({ error: true });
    }
    
  }

  componentDidMount() {
    this.generateCode();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.code !== prevProps.code) {
      this.generateCode();
    }
  }
  
  render() {
    if (this.state.error) return null;
    return (
      <div className="barcode-container">
        <svg className="barcode" preserveAspectRatio="xMidYMid slice"></svg>
      </div>
    );
  }
}

export default BarCode;