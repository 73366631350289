const _components = [];

const i18nWatcher = {
  add: component => _components.push(component),
  remove: function(obj) {
    const index = _components.findIndex(el => el === obj);
    if (index !== -1) {
      _components.splice(index, 1);
    }
  },
  getAll: () => _components
}

Object.freeze(i18nWatcher);
export default i18nWatcher;