import React from 'react';
import { useTranslation } from 'react-i18next'
import gSmallBw from 'assets/g_visuSmallBw.jpg';
import gMedBw from 'assets/g_visuMedBw.jpg';
import gLargeBw from 'assets/g_visuLargeBw.jpg';
import gLargeVisu from 'assets/g_large_visual.jpg';
import gTinyVisu from 'assets/g_tiny_visual.jpg';
import gVsCushions from 'assets/g_vs_cushions.jpg';
import getI18nEn from 'i18nEn';
import ImageContent from './ImageContent';
import Warn from './Warn';

function GuidelinesPageVisuals() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });

  return (
    <div className="content content-imagelinks" id="content-visuals">
      <div className="container">
        <h2 className="main">{ t('Visuals - sizes') }</h2>
        <div className="grid trailer-1">
          <div className="col-1">
            <ImageContent img={gSmallBw}>
              <h3>{ t('Small backwall') }</h3>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 324, h: 130 }) }
              </p>
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gMedBw}>
              <h3>{ t('Medium backwall') }</h3>
              <p>
                { t('Central visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 324, h: 295 }) }
              </p>
              <p>
                { t('Side visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 230, h: 393 }) }
              </p>
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gLargeBw}>
              <h3>{ t('Large backwall') }</h3>
              <p>
                { t('Central visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 324, h: 415 }) }
              </p>
              <p>
                { t('Side visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 230, h: 393 }) }
              </p>
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gLargeVisu}>
              <h3>{ t('Large visual') }</h3>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 226, h: 393 }) }<br/>
              </p>
            </ImageContent>
          </div>
        </div>
        <div className="grid trailer-1">
          <div className="col-1">
            <ImageContent img={gTinyVisu}>
              <h3>{ t('Tiny visual') }</h3>
              <h4>{ t('Tiny highlighter') }</h4>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 226, h: 263 }) }
              </p>
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gTinyVisu}>
              <h3>{ t('Square visual') }</h3>
              <h4>{ t('Collection highlighter') }</h4>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 356, h: 393 }) }
              </p>
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gVsCushions}>
              <h3>{ t('Visual support 2 cushions') }</h3>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 106, h: 88 }) }
              </p>
              { t('Recommended material: photographic paper') }
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={gVsCushions}>
              <h3>{ t('Visual support 2 cushions') }</h3>
              <p>
                { t('Visual size') }:<br/>
                { t('merchandising.displays.sizes2d', { w: 106, h: 180 }) }<br/>
              </p>
              { t('Recommended material: photographic paper') }
            </ImageContent>
          </div>
        </div>
        <Warn>
          { t('Note: Dimensions to be checked with the supplier depending on support material chosen locally.') }
        </Warn>
        
      </div>
    </div>
  );
}

export default GuidelinesPageVisuals;
