/* eslint-disable arrow-body-style, react/no-unused-state */
import React from 'react';
import WatchTeaser from 'components/WatchTeaser';

class RelatedProducts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      position: 0,
      size: 5,
    };

    this.btPrev = this.btPrev.bind(this);
    this.btNext = this.btNext.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  btNext() {
    this.setState((prevState, props) => {
      const pos = prevState.position < (props.related.length - prevState.size) ?
        prevState.position + 1 : prevState.position;
      return { position: pos };
    });
  }

  btPrev() {
    this.setState((prevState) => {
      const pos = prevState.position > 0 ? prevState.position - 1 : prevState.position;
      return { position: pos };
    });
  }

  updateSize() {
    const sizes = [
      { min: 1200, size: 5 },
      { min: 800, size: 4 },
      { min: 640, size: 3 },
      { min: 420, size: 4 },
      { min: 0, size: 3 }
    ];
    let size = null;
    for (let i = 0; i < sizes.length; i++) {
      if (window.innerWidth >= sizes[i].min) {
        size = sizes[i].size;
        break;
      }
    }
    if (size !== this.state.size) {
      this.setState({ size: size, position: 0 });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateSize);
    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  render() {
    const { related } = this.props;
    const { position, size } = this.state;
    const style = {};
    if (related.length < 1) {
      return null;
    }
    style.left = (-1 * (Math.round(100 * 100 / size) / 100) * position) + '%';
    const isPrevActive = position > 0;
    const isNextActive = position < (related.length - size);
    const styleTeaser = {
      width: (Math.round(100 * 100 / size) / 100) + '%'
    };
    return (
      <div className={`watch-related${size < related.length ? ' with-nav' : ''}`}>
        {size < related.length && (
          <div className="nav">
            <button className={`bt bt-prev${isPrevActive ? '' : ' disabled'}`} type="button" onClick={this.btPrev}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
                <path d="M0,10L8.5,1.5C10,0,12,2,10.5,3.5L4,10L10.5,16.5C12,18,10,20,8.5,18.5Z" fill="#706f6e"/>
              </svg>
            </button>
            <button className={`bt bt-next${isNextActive ? '' : ' disabled'}`} type="button" onClick={this.btNext}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 20">
                <path d="M12,10L3.5,1.5C2,0,0,2,1.5,3.5L8,10L1.5,16.5C0,18,2,20,3.5,18.5Z" fill="#706f6e"/>
              </svg>
            </button>
          </div>
        )}
        <div className="watches">
          <div className="watches-inside" style={style}>
            {related.map(watch => (
              <WatchTeaser style={styleTeaser} size="mini" watch={watch} key={watch.sku} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default RelatedProducts;
