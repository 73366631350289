import React from 'react';
import { useTranslation } from 'react-i18next';


export default function ProductStock(props) {
  const { product } = props;
  const { t } = useTranslation();

  if (product.stock === null) {
    return null
  }
  return (
    <div className="stock-container">
      <div className={`stock stock-${product.stock}`}></div>
      <span className="stock-text">{t('stock.' + product.stock)}</span>
    </div>
  );
}
