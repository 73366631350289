/* eslint-disable react/no-unused-state */
import React from 'react';
import Spinner from 'components/Spinner';
import ProductsGrouped from 'models/ProductsGrouped';
import NoveltiesGrouped from 'models/NoveltiesGrouped';
import Products from 'models/Products';
import WatchTeaser from 'components/WatchTeaser';
import WatchGroup, { getFamilyImage } from 'components/WatchGroup';
import FilterNav from 'components/filters/FilterNav';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next'
import AppContext from 'utils/AppContext';
import background_novelties from 'assets/background_novelties.jpg';
import Breadcrumb from 'components/nav/Breadcrumb';
import ProductPriceComment from 'components/product/ProductPriceComment';

function LoadMore(props) {
  const { t } = useTranslation();
  if (props.loaded) {
    return (
      <div className="load-more">
        <button className="btn" type="button" onClick={props.click}>{t('View more')}</button>
      </div>
    );
  }
  else {
    return <div className="load-more"><Spinner/></div>
  }
}

class ProductsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      loadMoreLoaded: true,
      data: null,
      lang: i18n.language,
      loadMore: false,
      sort: props.default_sort ?? 'family',
      country: props.country,
      products: []
    };

    this.setFilter = this.setFilter.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.load = this.load.bind(this);
  }

  addProducts(init) {
    const { country, token } = this.context;
    let products;
    const filters = this.context.getFiltersList();
    if (this.state.sort === 'family') {
      products = new ProductsGrouped(country, token);
      products.page_size = 50;
    }
    else if (this.state.sort === 'novelties_asc' ||
    this.state.sort === 'novelties_desc') {
      products = new NoveltiesGrouped(country, token);
      products.sort = this.state.sort;
      products.page_size = 50;
    }
    else {
      products = new Products(country, token);
      products.sort = this.state.sort;
      products.page_size = 48;
    }
    
    if (this.props.collection !== null) {
      products.category = this.props.collection;
    }
    else if (this.props.isNovelties) {
      products.category = 'novelties';
    }

    if (this.props.search !== null) products.search = this.props.search;
    products.lang = this.state.lang;
    products.page = this.state.products.length + 1;
    this.context.activeFilters.forEach(function(filter) {
      if (filters.indexOf(filter.name) !== -1) products[filter.name] = filter.value;
    });
    products.fetch(this, (model) => {
      if (model.data.meta.subset.count < 1 && this.context.activeFilters.size > 0) {
        this.resetFilters();
      }
      else {
        this.setState(prevState => {
          const newProducts = init ? [model.data] : [...prevState.products, model.data];
          return {
            isLoaded: true,
            data: model,
            loadMoreLoaded: true,
            loadMore: model.data.meta.subset.page_count > newProducts.length,
            forceUpdate: false,
            products: newProducts
          };
        }, () => {
          this.setStorage();
        });
      }

    });
  }

  handleScroll(event) {
    try {
      sessionStorage.setItem('products-scroll', window.scrollY);
    } catch (e) {
      try {
        sessionStorage.removeItem('products-scroll');
      } catch (e2) {
        // continue regardless of error
      }
    }
  }

  setStorage() {
    const data = {
      products: this.state.products,
      filters: [],
      sort: this.state.sort,
      country: this.context.country,
      collection: this.props.collection,
      lang: this.state.lang,
    };
    this.context.activeFilters.forEach(function(filter) {
      data.filters.push(filter);
    });
    try {
      sessionStorage.setItem('products-data', JSON.stringify(data));
    } catch (e) {
      try {
        sessionStorage.removeItem('products-data');
      } catch (e2) {
        // continue regardless of error
      }
    }
  }

  load(firstLoad) {
    if (firstLoad && this.props.isBackBtn) {
      const data = sessionStorage.getItem('products-data');
      if (data) {
        const parsedData = JSON.parse(data);
        if (parsedData.lang === this.state.lang &&
            parsedData.country === this.context.country &&
            parsedData.collection === this.props.collection
        ) {

          const { meta } = parsedData.products[parsedData.products.length - 1];
          const newState = {
            isLoaded: true,
            loadMoreLoaded: true,
            loadMore: meta.subset.page_count > parsedData.products.length,
            preventReload: true,
            sort: parsedData.sort,
            products: parsedData.products
          };
          if ('filters' in parsedData) {
            this.context.setFilters(parsedData.filters);
          }

          this.setState(newState, function() {
            const scroll = sessionStorage.getItem('products-scroll');
            window.scroll(0, scroll);
          });
          return;
        }

      }
    }
    this.addProducts(true);
  }

  onLoadMore = () => {
    this.setState({ loadMoreLoaded: false });
    this.addProducts();
  };

  componentDidMount() {
    i18nWatcher.add(this);
    window.addEventListener('scroll', this.handleScroll);
    if (this.mounted) {
      return;
    }

    this.load(true);
    this.mounted = true;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    i18nWatcher.remove(this);
  }

  getProducts() {
    const products = {
      items: [],
      meta: this.state.products[this.state.products.length - 1].meta
    };
    if (this.state.sort === 'family') {
      const isSet = {};
      for (let i = 0; i < this.state.products.length; i++) {
        for (let j = 0; j < this.state.products[i].items.length; j++) {
          const item = this.state.products[i].items[j];
          if (!(('_' + item.name) in isSet)) {
            products.items.push({
              id: item.name,
              items: [].concat(item.items),
              name: item.name,
              family_front_image: getFamilyImage(item.image_start),
              family_back_image: getFamilyImage(item.image_end),
              images_end_2: item.images_end_2,
              meta: item.meta
            });
            isSet['_' + item.name] = products.items.length - 1;
          }
          else {
            for (let k = 0; k < item.items.length; k++) {
              if (!products.items[isSet['_' + item.name]].items.find(it => it.sku === item.items[k].sku)) {
                products.items[isSet['_' + item.name]].items.push(item.items[k]);
              }
            }
          }
        }
      }
    } else if (this.state.sort === 'novelties_asc' ||
        this.state.sort === 'novelties_desc') {
      const isSet = {};
      for (let i = 0; i < this.state.products.length; i++) {
        for (let j = 0; j < this.state.products[i].items.length; j++) {
          const item = this.state.products[i].items[j];
          if (!((item.pk) in isSet)) {
            products.items.push({
              id: item.pk,
              items: [].concat(item.items),
              name: item.resource_name,
              month: item.month,
              family_front_image: background_novelties,
              family_back_image: background_novelties,
              images_end_2: item.images_end_2,
              meta: item.meta
            });
            isSet[item.pk] = products.items.length - 1;
          }
          else {
            for (let k = 0; k < item.items.length; k++) {
              products.items[isSet[item.pk]].items.push(item.items[k]);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < this.state.products.length; i++) {
        products.items = products.items.concat(this.state.products[i].items);
      }
      const skus = [];
      products.items = products.items.filter(it => {
        if (skus.indexOf(it.sku) !== -1) {
          return false;
        }
        skus.push(it.sku);
        return true;
      });
    }
    return products;
  }

  setFilter(name, value) {
    if (value === null) {
      this.context.removeFilter({ name: name, value: value });
    }
    else {
      this.context.addFilter({ name: name, value: value });
    }
  }

  getActiveFilterId(id) {
    return this.context.getFilter(id);
  }

  setSort(name) {
    if (name !== this.state.sort) {
      this.setState({ sort: name, isLoaded: false, products: [] }, () => this.load());
    }
  }

  resetFilters() {
    this.context.resetFilters();
  }

  hasFilters() {
    return this.context.activeFilters.length > 0;
  }

  componentDidUpdate(prevProps, prevState) {
    const newState = { isLoaded: false, products: [] };
    const filtersHasChange = this.props.filtersHash !== prevProps.filtersHash;
    if (
      this.state.lang !== prevState.lang ||
      this.state.country !== this.context.country ||
      this.props.collection !== prevProps.collection ||
      this.props.search !== prevProps.search ||
      this.props.isNovelties !== prevProps.isNovelties ||
      filtersHasChange
    ) {
      if (this.state.country !== this.context.country) {
        newState.country = this.context.country;
      }
      this.setState(newState, () => {
        this.load(this.props.collection !== prevProps.collection);
      });

    }
  }

  render() {
    const { isLoaded, loadMore, loadMoreLoaded } = this.state;
    const { t } = this.props;
    if (!isLoaded) {
      return <Spinner position="center"/>;
    } else {
      const hide_novelties = this.props.isNovelties;
      const { items, meta } = this.getProducts();
      const menu = meta.menu ? meta.menu : [];
      return (
        <>
          {('menu' in meta && this.props.showBreadcrumb) && (
            <Breadcrumb menu={[
              {
                path: 'collection',
                name: t('Current collections')
              },
              ...menu
            ]} />
          )}
          <FilterNav
            meta={meta}
            productsPage={this}
            hasFilters={this.hasFilters()}
            enable_novelties={this.props.isNovelties}
            enable_search={this.props.search !== null} />
          {(this.state.sort === 'family' || this.state.sort === 'novelties_asc' ||
            this.state.sort === 'novelties_desc') && (
            <div className="watches-list">
              {items.map(item => (
                <WatchGroup item={item} key={item.name} hide_novelties={hide_novelties} />
              ))}
            </div>
          )}
          {this.state.sort !== 'family' && this.state.sort !== 'novelties_asc' &&
          this.state.sort !== 'novelties_desc' && (
            <div className="watches-list grid">
              {items.map(watch => (
                <WatchTeaser
                  watch={watch}
                  key={watch.sku}
                  hide_novelties={hide_novelties} />
              ))}
            </div>
          )}
          {items.length < 1 && (
            <h2>{t('No products match your search')}</h2>
          )}
          <p className="price-legend">* <ProductPriceComment/></p>
          {loadMore &&
            <LoadMore loaded={loadMoreLoaded} click={this.onLoadMore} />
          }
        </>
      );
    }
  }
}
ProductsList.contextType = AppContext;
ProductsList.defaultProps = {
  collection: null,
  isNovelties: false,
  search: null,
  showBreadcrumb: true
};

export default withTranslation()(ProductsList);
