import React from 'react';
import { useTranslation } from 'react-i18next';

import nba from 'assets/logos/nba.jpg';
import fiba from 'assets/logos/fiba.jpg';
import tdf from 'assets/logos/tdf.jpg';
import vuelta from 'assets/logos/vuelta.jpg';
import uci from 'assets/logos/uci.jpg';
import motogp from 'assets/logos/motogp.jpg';
import sbk from 'assets/logos/sbk.jpg';
import ch_cup from 'assets/logos/ch_cup.jpg';
import chal_cup from 'assets/logos/chal_cup.jpg';
import iihf from 'assets/logos/iihf.jpg';

function AboutPartners() {
  const { t } = useTranslation();
  return (
    <>
      <div className="logos-titles">
        {t('Basketball')}
      </div>
      <div className="logos">
        <img src={nba} alt="" />
        <img src={fiba} alt="" />
      </div>
      <div className="logos-titles">
        {t('Cycling')}
      </div>
      <div className="logos">
        <img src={tdf} alt="" />
        <img src={vuelta} alt="" />
        <img src={uci} alt="" />
      </div>
      <div className="logos-titles">
        <span>{t('Motorcycling')}</span>
        <span>{t('Ice hockey')}</span>
      </div>
      <div className="logos logos-split">
        <div className="content logos">
          <img src={motogp} alt="" />
          <img width="141" height="37" src={sbk} alt="" />
        </div>
        <div className="content logos">
          <img src={iihf} alt="" />
        </div>
      </div>
      <div className="logos-titles">
        {t('Rugby')}
      </div>
      <div className="logos">
        <img src={ch_cup} alt="" />
        <img src={chal_cup} alt="" />
      </div>
    </>
  );
}

export default AboutPartners;