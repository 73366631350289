import React from 'react';
import { Link } from 'react-router-dom'
import Watch from 'models/Watch';
import Pictos from 'components/Pictos';
import WatchTeaserContent from 'components/WatchTeaserContent';

function WatchTeaser(props) {
  const watch = props.watch instanceof Watch ? props.watch : new Watch(props.watch);

  const size = 'size' in props ? props.size : 'full';
  const teaserClasses = ['watch', 'watch-teaser', 'family-' + watch.familyId, 'watch-' + props.watch.sku];
  if (watch.soldier !== null) {
    teaserClasses.push('with-image');
  }

  return (
    <div className={teaserClasses.join(' ')} key={props.sku} style={props.style}>
      <Link to={'/product/' + props.watch.sku} >
        <WatchTeaserContent 
          size={size} 
          watch={watch} 
          hide_novelties={props.hide_novelties}
          hide_diameter={props.hide_diameter}
          hide_stock={props.hide_stock} />
      </Link>
      {!props.hide_pictos && (
        <Pictos product={watch}/>
      )}
      {props.children}
    </div>
  );
}

export default WatchTeaser;
