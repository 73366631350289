/* eslint camelcase: "off" */
import ProductList from 'models/ProductList';

class NoveltiesGrouped extends ProductList {
  constructor(country, token) {
    super('api/watches/vm/novelties', country, token);
  }

}

export default NoveltiesGrouped;
