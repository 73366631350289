import React from 'react';
import { useTranslation } from 'react-i18next';
import home from 'assets/s_m_home.jpg';
import sRubber from 'assets/s_rubber.jpg';
import sLeather from 'assets/s_leather.jpg';
import sSteel from 'assets/s_steel.jpg';
import sNato from 'assets/s_nato.jpg';

function StrapPageMaterials() {
  const { t } = useTranslation();
  return (
    <div className="straps-materials contents-page">
      <div className="content content-head content-head-basic">
        <div className="container relative">
          <img src={home} alt="" />
          <div className="grid above">
            <div className="col-2">
            </div>
            <div className="col-2">
              <h1>{ t('Straps materials') }</h1>
            </div>
          </div>
        </div>
        <div className="band"></div>
      </div>
      <div className="content content-layout">
        <div className="container">
          <div className="grid middle">
            <div className="col-2">
              <img src={sLeather} alt=""/>
            </div>
            <div className="col-2">
              <h2>{ t('straps.materials.leather.title') }</h2>
              <p>{ t('straps.materials.leather') }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="content content-layout">
        <div className="container">
          <div className="grid middle">
            <div className="col-2">
              <h2>{ t('straps.materials.steel.title') }</h2>
              <p>{ t('straps.materials.steel') }</p>
            </div>
            <div className="col-2">
              <img src={sSteel} alt=""/>
            </div>
          </div>
        </div>
      </div>
      <div className="content content-layout">
        <div className="container">
          <div className="grid middle">
            <div className="col-2">
              <img src={sNato} alt=""/>
            </div>
            <div className="col-2">
              <h2>{ t('straps.materials.nato.title') }</h2>
              <p>{ t('straps.materials.nato') }</p>
            </div>
          </div>
        </div>
      </div>
      <div className="content content-layout">
        <div className="container">
          <div className="grid middle">
            <div className="col-2">
              <h2>{ t('straps.materials.rubber.title') }</h2>
              <p>{ t('straps.materials.rubber') }</p>
            </div>
            <div className="col-2">
              <img src={sRubber} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrapPageMaterials;
