import React from 'react';
import { withTranslation } from 'react-i18next'
import Collections from 'components/nav/Collections';
import StaticNavigation from 'components/nav/StaticNavigation';
import TogglePrice from 'components/nav/TogglePrice'
import NoveltiesNavigation from 'components/nav/NoveltiesNavigation';

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      selectedMenu: null
    };

    this.onExpand = this.onExpand.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

  }

  catchEvents(e) {
    e.stopPropagation();
  }

  componentDidMount() {
    window.addEventListener('click', this.closeMenu.bind(this));
  }

  onExpand(e) {
    e.stopPropagation();
    this.setState(prevState => ({ opened: !prevState.opened }));
  }

  closeMenu() {
    this.setState({
      opened: false
    });
  }

  setSelectedMenu(menu) {
    this.setState({
      selectedMenu: menu
    });
  }

  renderCollections() {
    const props = {
      close: this.closeMenu,
      selectedMenu: (m) => this.setSelectedMenu(m)
    };
    if (this.state.selectedMenu !== null &&
      (this.state.selectedMenu === 'static' || this.state.selectedMenu === 'novelties')) {
      props.style = { display: 'none' };
    }
    return (
      <Collections {...props} />
    )
  }

  renderNovelties() {
    if (this.state.selectedMenu === null ||
       this.state.selectedMenu === 'novelties') {
      return (
        <NoveltiesNavigation
          status={this.state.selectedMenu}
          close={this.closeMenu}
          selectedMenu={(m) => this.setSelectedMenu(m)}/>
      )
    }
    return null;
  }

  renderStaticNavigation() {
    if (this.state.selectedMenu === null ||
       this.state.selectedMenu === 'static') {
      return (
        <StaticNavigation
          status={this.state.selectedMenu}
          close={this.closeMenu}
          selectedMenu={(m) => this.setSelectedMenu(m)}/>
      )
    }
    return null;
  }

  render() {
    /* eslint-disable jsx-a11y/no-static-element-interactions,
                      jsx-a11y/click-events-have-key-events,
                      react/button-has-type */
    const classes = ['menu-menu'];
    const btnProps = {
      type: 'button',
      onClick: this.onExpand,
    };
    if (this.state.opened) {
      classes.push('opened');
      btnProps.className = 'opened';
    }
    return (
      <div className={`menu-container ${this.state.selectedMenu === null ? 'root' : ''}`} onClick={this.catchEvents}>
        <button {...btnProps}>
          <span className="bar"></span>
          Menu
        </button>
        <div className={classes.join(' ')}>
          <div className="menu-menu-inside">
            {this.renderNovelties()}
            {this.renderCollections()}
            {this.renderStaticNavigation()}
            <TogglePrice/>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Menu);
