/* eslint-disable react/no-unused-state, react/no-danger */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Watch from 'models/Watch';
import i18nWatcher from 'utils/i18nWatcher';
import i18n from 'i18next';
import AppContext from 'utils/AppContext';
import Spinner from 'components/Spinner';
import Breadcrumb from './Breadcrumb';

class BreadcrumbStrapSku extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: null,
      lang: i18n.language
    };
  }

  load() {
    const { country, token } = this.context;
    this.product = new Watch(null, country, token);
    this.product.sku = this.props.sku;
    this.product.lang = this.state.lang;
    this.product.fetch(this, null, {
      on404: res => {
        this.setState({ error: 404 });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.sku !== prevProps.sku
    ) {
      this.setState({ isLoaded: false });
      this.load();
    }
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load();
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  render() {
    const { error, isLoaded } = this.state;
    const { t } = this.props;

    if (error) {
      return (
        <p>{t('Unable to load breadcrumb for {{ sku }}', { sku: this.props.sku })}</p>
      );
    } else if (!isLoaded) {
      return (
        <Spinner />
      );
    } else {
      return (
        <Breadcrumb menu={this.product.menu}>
          <Link to={'/product/' + this.props.sku} >{this.product.originalSku}</Link>
          {this.props.children}
        </Breadcrumb>
      )
    }
  }
}
BreadcrumbStrapSku.contextType = AppContext;

export default withTranslation()(BreadcrumbStrapSku);
