/* eslint camelcase: "off" */
import StrapsList from 'models/StrapsList';

class Straps extends StrapsList {
  constructor(country, token) {
    super('api/straps', country, token);
  }

  set watch_sku(watch_sku) {
    if (watch_sku) this.setGetParamCheck('watch_sku', watch_sku);
    this._watch_sku = watch_sku;
  }
  
  get watch_sku() {
    return this._watch_sku;
  }
}

export default Straps;