import React from 'react';

function MessageBox(props) {
  const { context, size } = props;
  if (context === 'full') {
    return (
      <div className={'message-container full size-' + size}>
        <div className={`message message-${props.type || 'warning'}`}>
          {props.children}
        </div>
      </div>
    );
  }
  return (
    <div className="message-container inline">
      <div className={`message message-${props.type || 'warning'}`}>
        {props.children}
      </div>
    </div>
  );
}

export default MessageBox;