/* eslint no-return-assign: "error" */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.check = true;
    this.state = {
      focus: false,
      value: props.search ? props.search : ''
    };
  }

  onFocus = (e) => {
    this.setState({ focus: true, value: e.target.value });
  };

  onBlur = (e) => {
    this.setState({ focus: false, value: e.target.value });
  };

  onClear = (e) => {
    this.setState({ value: '' });
  };

  onChange = (e) => {
    this.setState({ value: e.target.value });
  };

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.value.length > 0) this.gotoSearch(e.target.value);
    }
  }

  onSearch = (e) => {
    if (this.state.value.length > 0) this.gotoSearch(this.state.value);
  }

  gotoSearch(search) {
    const event = new Event('close-dropdown', { bubbles: true, cancelable: true });
    this.node.dispatchEvent(event);
    this.props.history.push('/search?search=' + encodeURIComponent(search));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.search !== prevProps.search) {
      this.setState({ value: this.props.search });
    }
  }

  render() {
    const { t, id } = this.props;
    // eslint-disable-next-line max-len
    const icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.98 19.22"><title>search</title><path d="M17.57,19.22a1.45,1.45,0,0,1-1-.43L12.9,15a7.94,7.94,0,0,1-4.81,1.62A8.21,8.21,0,0,1,0,8.32,8.22,8.22,0,0,1,8.09,0a8.22,8.22,0,0,1,8.09,8.32,8.44,8.44,0,0,1-1.36,4.62l3.76,3.89a1.41,1.41,0,0,1,0,2A1.45,1.45,0,0,1,17.57,19.22ZM8.09,2.82a5.39,5.39,0,0,0-5.27,5.5,5.39,5.39,0,0,0,5.27,5.5,5.4,5.4,0,0,0,5.28-5.5A5.4,5.4,0,0,0,8.09,2.82Z" fill="#575756"/></svg>;
    const classes = ['search-form', 'container'];
    if (this.state.focus || this.state.value.length > 0) {
      classes.push('focus');
    }
    return (
      <div ref={node => (this.node = node)} className={classes.join(' ')}>
        <label htmlFor={id}>{t('Search for a product')}</label>
        <input
          id={id}
          name="search"
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          value={this.state.value} />
        <button type="button" className="btn-clear" onClick={this.onClear}>{t('Clear')}</button>
        <button type="button" className="btn-search" onClick={this.onSearch}>{icon}{t('Search')}</button>
      </div>
    );
  }
}

export default withRouter((withTranslation()(Search)));
