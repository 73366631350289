import React from 'react';
import Spinner from 'components/Spinner';
import { Link } from 'react-router-dom'
import i18n from 'i18next';
import { withTranslation } from 'react-i18next'
import Novelties from 'models/Novelties';
import AppContext from 'utils/AppContext';
import i18nWatcher from 'utils/i18nWatcher';

class NoveltiesNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      data: null,
      lang: i18n.language
    };
    this.onBack = this.onBack.bind(this);
  }

  componentDidMount() {
    i18nWatcher.add(this);
    this.load(true);
  }

  componentWillUnmount() {
    i18nWatcher.remove(this);
  }

  onBack() {
    this.props.selectedMenu(null);
  }

  load(init) {
    const { country, token } = this.context;
    const novelties = new Novelties(country, token);
    novelties.lang = this.state.lang;
    if (!init) this.props.selectedMenu(null)
    novelties.fetch(this);
  }

  nav() {
    this.props.selectedMenu('novelties');
  }

  render() {
    const { isLoaded, data } = this.state;
    const { t, close } = this.props;

    if (!isLoaded) {
      return <Spinner color="white"/>;
    }
    if (this.props.status === null && data._data.novelties.length > 0) {
      return (
        <div className="nav-collection--item">
          <button
            type="button"
            onClick={() => this.nav()}>
            { t('Novelties') }
          </button>
        </div>
      );
    } else if (this.props.status === 'novelties') {
      return (
        <div className="nav-static">
          <div className="nav-collection--item parent">
            <button
              type="button"
              onClick={this.onBack}>
              { t('Novelties') }
            </button>
          </div>
          <div className="nav-collection--item see-all">
            <Link to="/novelties" onClick={close} >{ t('See all') }</Link>
          </div>
          { data._data.novelties.map(item => (
            <div key={item.pk} className="nav-collection--item">
              <Link to={'/novelties/' + item.pk} onClick={close}>{item.name}</Link>
            </div>
          ))}
        </div>
      );
    }
    return null;
  }
}

NoveltiesNavigation.contextType = AppContext;

export default withTranslation()(NoveltiesNavigation);
