import React, { useContext } from 'react';
import ProductsList from 'components/productlist/ProductsList';
import Search from 'components/nav/Search';
import useQuery from 'utils/useQuery';
import AppContext from 'utils/AppContext';

function SearchPage(props) {
  const search = useQuery().get('search');
  const context = useContext(AppContext);
  return (
    <div className="container">
      <Search search={search} id="page-search" />
      <ProductsList
        search={search}
        default_sort="search"
        isBackBtn={props.isBackBtn}
        filtersHash={props.filtersHash}
        store={context.store} />
    </div>
  );
}

export default SearchPage;
