/* eslint camelcase: "off" */
import Model from 'models/Model';

class StrapList extends Model {
  constructor(endpoint, country, token) {
    super(endpoint);
    this.setGetParam('token', token);
    this.setGetParam('country', country);
    this._filter_strap_type = null;
    this._filter_strap_color_2 = null;
    this._filter_strap_lugs = null;
    this._filter_strap_buckle = null;
    this._sort = null;
    this._page = null;
    this._page_size = null;
    this._search = null;
    this._noitems = null;
  }

  set filter_strap_type(filter_strap_type) {
    this.setGetParamCheck('filter_strap_type', filter_strap_type);
    this._filter_strap_type = filter_strap_type;
  }

  get filter_strap_type() {
    return this._filter_strap_type;
  }

  set filter_strap_color_2(filter_strap_color_2) {
    this.setGetParamCheck('filter_strap_color_2', filter_strap_color_2);
    this._filter_strap_color_2 = filter_strap_color_2;
  }

  get filter_strap_color_2() {
    return this._filter_strap_color_2;
  }

  set filter_strap_buckle(filter_strap_buckle) {
    this.setGetParamCheck('filter_strap_buckle', filter_strap_buckle);
    this._filter_strap_buckle = filter_strap_buckle;
  }

  get filter_strap_buckle() {
    return this._filter_strap_buckle;
  }

  set filter_strap_lugs(filter_strap_lugs) {
    this.setGetParamCheck('filter_strap_lugs', filter_strap_lugs);
    this._filter_strap_lugs = filter_strap_lugs;
  }

  get filter_strap_lugs() {
    return this._filter_strap_lugs;
  }

  set sort(sort) {
    this.setGetParamCheck('sort', sort);
    this._sort = sort;
  }

  get sort() {
    return this._sort;
  }

  set page(page) {
    this.setGetParamCheck('page', page);
    this._page = page;
  }

  get page() {
    return this._page;
  }

  set page_size(page_size) {
    this.setGetParamCheck('page_size', page_size);
    this._page_size = page_size;
  }

  get page_size() {
    return this._page_size;
  }

  set search(search) {
    if (search) this.setGetParamCheck('search', search);
    this._search = search;
  }

  get search() {
    return this._search;
  }

  set noitems(noitems) {
    this.setGetParamCheck('noitems', noitems);
    this._noitems = noitems;
  }

  get noitems() {
    return this._noitems;
  }
}

export default StrapList;
