import React from 'react';
import { withTranslation } from 'react-i18next';
import Dropdown from 'components/Dropdown';
import Filter from 'components/filters/Filter';

class StrapsFilters extends React.Component {
  constructor(props) {
    super(props);
    this.check = true;
  }

  reset = () => {
    this.props.productsPage.resetFilters();
  };

  render() {
    const { t } = this.props;

    const {
      filter_strap_type,
      filter_strap_color_2,
      filter_strap_buckle,
      filter_strap_lugs,
    } = this.props.meta;

    const dropdownAttrs = {
      name: 'filters',
      title: t('Filter')
    };

    return (
      <Dropdown {...dropdownAttrs}>
        {this.props.productsPage.hasFilters() && (
          <button className="btn" type="button" onClick={this.reset}>{t('Reset filters')}</button>
        )}
        <div className="filters-list">
          <div className="col">
            <Filter id="filter_strap_type" filter={filter_strap_type} productsPage={this.props.productsPage} />
            <Filter id="filter_strap_buckle" filter={filter_strap_buckle} productsPage={this.props.productsPage} />
          </div>
          <div className="col">
            <Filter id="filter_strap_color_2" filter={filter_strap_color_2} productsPage={this.props.productsPage} />
          </div>
          <div className="col">
            <Filter id="filter_strap_lugs" filter={filter_strap_lugs} productsPage={this.props.productsPage} />
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(StrapsFilters);
