/* eslint-disable max-len */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next'
import AppContext from 'utils/AppContext'

function TogglePrice() {
  const { t } = useTranslation();
  const context = useContext(AppContext);

  if (!context.user.show_prices) {
    return null
  }
  return (
    <button
      type="button"
      className="btn-price"
      onClick={() => context.setHidePrice(!context.hidePrice)}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.49 17.47">
        <title>price</title>
        <path d="M10.47,11.28a1.57,1.57,0,0,1-1.83,1.48c-1-.1-1.36-.77-1.72-1.54a.87.87,0,0,0-1.24-.48A.93.93,0,0,0,5.2,12a3.06,3.06,0,0,0,2.22,2.1c.4.12.71.21.79.7a.66.66,0,0,0,.5.38c.18,0,.52-.18.54-.32.08-.57.46-.68.91-.8a3.08,3.08,0,0,0,.79-.35,3,3,0,0,0,.11-5,13.25,13.25,0,0,0-1.94-.76,6.23,6.23,0,0,1-1.2-.55,1.46,1.46,0,0,1-.53-1.66,1.53,1.53,0,0,1,1.43-1,1.41,1.41,0,0,1,1.3,1c.05.12.08.25.13.38.25.65.65.88,1.19.69A1,1,0,0,0,12,5.42a3.27,3.27,0,0,0-2.07-2,.82.82,0,0,1-.65-.64.73.73,0,0,0-.55-.44.7.7,0,0,0-.56.43.72.72,0,0,1-.59.62,3.1,3.1,0,0,0-2,2A2.88,2.88,0,0,0,7.1,9.06c.72.36,1.51.6,2.27.89s1.1.71,1.1,1.33M7.79,17.47a23.2,23.2,0,0,1-2.65-.8A8.55,8.55,0,0,1,0,8.17a8.75,8.75,0,1,1,10,9.2,1.17,1.17,0,0,0-.26.1Z" transform="translate(0 0)" fill="white"/>
      </svg>
      {context.hidePrice ? t('Show Price') : t('Hide Price')}
    </button>
  );
}

export default TogglePrice;
