import React, { useState } from 'react';
import { withTranslation } from 'react-i18next'
import Spinner from 'components/Spinner'
import LoadingPage from 'pages/LoadingPage'
import { AppContextProvider } from 'utils/AppContext';
import Storage from 'utils/Storage';
import * as Sentry from "@sentry/react";
import ErrorPage from 'pages/ErrorPage';
import { initI18n } from './i18n';

const { Suspense } = React;

function App() {
  const [storageLoaded, setStorageLoaded] = useState(false);
  Storage.load().then(() => {
    initI18n().then(() => {
      setStorageLoaded(true);
    });

  });
  if (storageLoaded) {
    const LoadingPageI18n = withTranslation()(LoadingPage);
    return (
      <AppContextProvider>
        <Suspense fallback={<Spinner />}>
          <Sentry.ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag("country", Storage.getItem('country'));
              scope.setTag("language", Storage.getItem('language'));
            }}
            fallback={({ error, componentStack, resetError }) => (
              <ErrorPage error={error} componentStack={componentStack} />
            )}>
            <LoadingPageI18n/>
          </Sentry.ErrorBoundary>
        </Suspense>
      </AppContextProvider>
    );
  }
  else {
    return <Spinner />;
  }

}

export default App;
