import Model from 'models/Model';
import { getFamilyImage } from 'components/WatchGroup';
import imgGetUrl from 'utils/imgGetUrl';

class Watch extends Model {
  constructor(data, country, token) {
    super('api/watches/slug/{sku}');
    this.setGetParam('token', token);
    this.setGetParam('country', country);
    this._sku = null;
    if (data) {
      this.data = data;
      this._sku = data.sku;
    }
  }

  set sku(sku) {
    if (this.params && 'sku' in this.params) {
      this.params.sku = sku;
    }
    this._sku = sku;
  }

  get sku() {
    return this._data.sku;
  }

  get originalSku() {
    return this._data.original_sku;
  }

  get name() {
    return this._data.resource_name;
  }

  get menu() {
    return this._data.menu;
  }

  get price() {
    return this._data.price;
  }

  get soldier() {
    return this._data.image_soldier;
  }

  get stock() {
    return this._data.stock_indicator;
  }

  get novelty_category() {
    return this._data.novelty_category;
  }

  get has_straps() {
    return this._data.has_straps;
  }

  get launch_date() {
    return this._data.launch_date;
  }

  get isNovelty() {
    return !!this.novelty_category;
  }

  get familyId() {
    if ('filter_family' in this._data && this._data.filter_family) {
      return this._data.filter_family.id;
    }
    return 'no-family';
  }

  get video() {
    if ('video_url' in this._data && this._data.video_url) {
      return this._data.video_url;
    }
    return null;
  }

  get related() {
    return this._data.linked_product_items;
  }

  get pictos() {
    if ('picto_label' in this._data) {
      return this._data.picto_label;
    }
    return []
  }

  get diameter() {
    if ('diameter3h9h' in this._data) {
      return Number(this._data.diameter3h9h);
    }
    return null;
  }

  getFamilyFrontImage() {
    return getFamilyImage(this._data.image_start);
  }

  getFamilyBackImage() {
    return getFamilyImage(this._data.image_end);
  }

  set data(data) {
    data.attrs = {};
    this._data = data;
  }

  getAttribute(name) {
    let value = null;
    let label = name;
    if (name in this._data) {
      const attr = this._data[name];
      if (Array.isArray(attr)) {
        value = attr.map((val) => val.label).join(', ');
      } else if (typeof attr === 'object' && attr !== null && 'label' in attr) {
        value = attr.label.length > 0 ? attr.label : attr.key;
      } else {
        value = attr;
      }
    } else {
      console.error(`Attribute ${name} doesn't exists !`);
    }
    if (('fields' in this._data.meta) && (name in this._data.meta.fields)) {
      label = this._data.meta.fields[name];
    }
    return { value: value, label: label }
  };

  getFamilyId() {
    return this._data.subfamily_label;
  }

  getSoldierUrl(style) {
    const imgInfos = imgGetUrl(style, this._data.image_soldier);
    return imgInfos.url;
  }

  getAllImages() {
    const allowed_types = ['detail1', 'detail1', 'detail3',
      'box', 'profil', 'wrist', 'caseback', 'set', 'open', 'closed', 'openclosed', 'superluminova'];
    const images = [];
    let sizeTotal = 0;
    this._data.images.forEach((img) => {
      if (allowed_types.includes(img.type)) {
        const imgInfos = imgGetUrl(img.type, img.path);
        images.push(imgInfos);
        sizeTotal += imgInfos.size;
      };
    });
    return {
      size: sizeTotal,
      images: images
    };
  }

  getLimitedAvailability() {
    if ('limited_availability' in this._data) {
      return this._data.limited_availability
    }
    return false
  }

  isSampleToOrder() {
    if ('sample' in this._data) {
      return this._data.sample
    }
    return false
  }

}

export default Watch;
