import Model from 'models/Model';

class Novelties extends Model {
  constructor(country, token) {
    super('api/categories');
    this.setGetParam('type', 'novelties');
    this.setGetParam('country', country);
    this.setGetParam('token', token);
  }
}

export default Novelties;
