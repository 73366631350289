import React from 'react';
import StrapsList from 'components/productlist/StrapsList';
import { useLocation } from 'react-router-dom';

function StrapsPage(props) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const sku = params.get('sku');
  const lug = Number(params.get('lug'));

  return (
    <div className="container">
      <StrapsList
        search={sku}
        lug={lug}
        isBackBtn={props.isBackBtn}
        filtersHash={props.filtersHash} />
    </div>
  );
}

export default StrapsPage;
