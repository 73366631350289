import React from 'react';
import * as Sentry from "@sentry/react";

const listIcons = {
  22129: 'picto_22129.svg',
  22133: 'picto_22133.svg',
  22168: 'picto_22168.svg',
  22172: 'picto_22172.svg',
  22177: 'picto_22177.svg',
  22180: 'picto_22180.svg',
  22190: 'picto_22190.svg',
  '80Hours': 'picto_80Hours.svg',
  A1: 'picto_A1.svg',
  B1: 'picto_B1.svg',
  B2: 'picto_B2.svg',
  B3: 'picto_B3.svg',
  B4: 'picto_B4.svg',
  B5: 'picto_B5.svg',
  B6: 'picto_B6.svg',
  B7: 'picto_B7.svg',
  B8: 'picto_B8.svg',
  B9: 'picto_B9.svg',
  BA: 'picto_BA.svg',
  BB: 'picto_BB.svg',
  BC: 'picto_BC.svg',
  BD: 'picto_BD.svg',
  BE: 'picto_BE.svg',
  BF: 'picto_BF.svg',
  BG: 'picto_BG.svg',
  BH: 'picto_BH.svg',
  BI: 'picto_BI.svg',
  BJ: 'picto_BJ.svg',
  BK: 'picto_BK.svg',
  BL: 'picto_BL.svg',
  BM: 'picto_BM.svg',
  BN: 'picto_BN.svg',
  BO: 'picto_BO.svg',
  BP: 'picto_BP.svg',
  BQ: 'picto_BQ.svg',
  BR: 'picto_BR.svg',
  'BRASS-PVD': 'picto_BRASS-PVD.svg',
  BS: 'picto_BS.svg',
  BT: 'picto_BT.svg',
  BU: 'picto_BU.svg',
  BV: 'picto_BV.svg',
  BY: 'picto_BY.svg',
  C1: 'picto_C1.svg',
  C2: 'picto_C2.svg',
  C3: 'picto_C3.svg',
  C4: 'picto_C4.svg',
  C5: 'picto_C5.svg',
  C6: 'picto_C6.svg',
  C7: 'picto_C7.svg',
  C8: 'picto_C8.svg',
  C9: 'picto_C9.svg',
  CA: 'picto_CA.svg',
  CB: 'picto_CB.svg',
  CD: 'picto_CD.svg',
  CE: 'picto_CE.svg',
  CH: 'picto_CH.svg',
  CI: 'picto_CI.svg',
  D1: 'picto_D1.svg',
  D2: 'picto_D2.svg',
  D3: 'picto_D3.svg',
  D4: 'picto_D4.svg',
  D5: 'picto_D5.svg',
  D6: 'picto_D6.svg',
  Diamonds: 'picto_Diamonds.svg',
  E1: 'picto_E1.svg',
  E2: 'picto_E2.svg',
  E3: 'picto_E3.svg',
  E4: 'picto_E4.svg',
  E5: 'picto_E5.svg',
  E6: 'picto_E6.svg',
  E7: 'picto_E7.svg',
  E8: 'picto_E8.svg',
  E9: 'picto_E9.svg',
  EA: 'picto_EA.svg',
  EB: 'picto_EB.svg',
  EC: 'picto_EC.svg',
  ED: 'picto_ED.svg',
  EE: 'picto_EE.svg',
  EF: 'picto_EF.svg',
  EG: 'picto_EG.svg',
  EH: 'picto_EH.svg',
  EI: 'picto_EI.svg',
  EJ: 'picto_EJ.svg',
  EK: 'picto_EK.svg',
  EM: 'picto_EM.svg',
  EN: 'picto_EN.svg',
  EO: 'picto_EO.svg',
  EP: 'picto_EP.svg',
  EQ: 'picto_EQ.svg',
  ER: 'picto_ER.svg',
  ES: 'picto_ES.svg',
  ET: 'picto_ET.svg',
  EU: 'picto_EU.svg',
  EX: 'picto_EX.svg',
  F1: 'picto_F1.svg',
  F2: 'picto_F2.svg',
  F3: 'picto_F3.svg',
  F4: 'picto_F4.svg',
  F5: 'picto_F5.svg',
  F6: 'picto_F6.svg',
  F7: 'picto_F7.svg',
  F8: 'picto_F8.svg',
  'Fabric-Leather-Strap': 'picto_Fabric-Leather-Strap.svg',
  Gold: 'picto_Gold.svg',
  LT: 'picto_LT.svg',
  Silicium: 'picto_Silicium.svg',
  Solar: 'picto_Solar.svg',
  TA: 'picto_TA.svg',
  Tactile: 'picto_Tactile.svg',
  V1: 'picto_V1.svg',
  V2: 'picto_V2.svg',
  V3: 'picto_V3.svg',
  V4: 'picto_V4.svg',
  V5: 'picto_V5.svg',
  V6: 'picto_V6.svg',
  V7: 'picto_V7.svg',
  V8: 'picto_V8.svg',
  W1: 'picto_W1.svg',
  W2: 'picto_W2.svg',
  W3: 'picto_W3.svg',
  W4: 'picto_W4.svg',
  W5: 'picto_W5.svg',
  W6: 'picto_W6.svg',
  W7: 'picto_W7.svg',
  W8: 'picto_W8.svg',
  W9: 'picto_W9.svg',
  WA: 'picto_WA.svg',
  WB: 'picto_WB.svg',
  WQ: 'picto_WQ.svg',
  WS: 'picto_WS.svg',
  WT: 'picto_WT.svg',
  WX: 'picto_WX.svg',
  alarm: 'picto_alarm.svg',
  alligator: 'picto_alligator.svg',
  altimeter: 'picto_altimeter.svg',
  chrono3: 'picto_chrono3.svg',
  compass: 'picto_compass.svg',
  compass_black: 'picto_compass_black.svg',
  dive: 'picto_dive.svg',
  fabric: 'picto_fabric.svg',
  'interchangeable-bracelet': 'picto_interchangeable-bracelet.svg',
  lap: 'picto_lap.svg',
  leather: 'picto_leather.svg',
  logbook_black: 'picto_logbook_black.svg',
  logbook_white: 'picto_logbook_white.svg',
  meteo: 'picto_meteo.svg',
  meteo_black: 'picto_meteo_black.svg',
  nato: 'picto_nato.svg',
  regatta: 'picto_regatta.svg',
  rubber: 'picto_rubber.svg',
  silicone: 'picto_silicone.svg',
  speed: 'picto_speed.svg',
  split: 'picto_split.svg',
  synthetic: 'picto_synthetic.svg',
  syntheticWithLeather: 'picto_syntheticWithLeather.svg',
  thermo: 'picto_thermo.svg',
  tide: 'picto_tide.svg',
  timer: 'picto_timer.svg',
  vibrating_time: 'picto_vibrating_time.svg'
};

function Picto(props) {
  const { icon, active, action } = props;
  const classes = ['picto'];
  if (active) classes.push('active');
  return (
    <button onClick={action} type="button" className={classes.join(' ')}>
      <span className="tooltip"><span>{icon.label}</span></span>
      <img alt="" key={icon.key} className={`pict-${icon.key}`} src={`/images/pictos/${listIcons[icon.key]}`} />
    </button>
  )
}

class Pictos extends React.Component {
  constructor(props) {
    super(props);
    const icons = this.props.product.pictos;
    this.state = {};
    for (const i in icons) {
      this.state[icons[i].key] = false;
    }

    this.onClickPicto = this.onClickPicto.bind(this);
    this.closeTooltips = this.closeTooltips.bind(this);
  }

  closeTooltips() {
    const newState = {};
    const icons = this.props.product.pictos;
    for (const i in icons) {
      newState[icons[i].key] = false;
    }
    this.setState(newState);
  }

  onClickPicto(e, key) {
    e.stopPropagation();
    const newState = {};
    const icons = this.props.product.pictos;
    for (const i in icons) {
      newState[icons[i].key] = icons[i].key === key;
    }
    this.setState(newState);
  }

  componentDidMount() {
    window.addEventListener('click', this.closeTooltips);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeTooltips);
  }

  render() {
    const { product, view } = this.props;
    const icons = product.pictos;
    const images = [];
    let diameter = null;
    if (view === 'full') {
      diameter = product.diameter;
    }
    for (const i in icons) {
      if (icons[i].key in listIcons) {
        images.push(
          <Picto
            action={(e) => this.onClickPicto(e, icons[i].key)}
            active={this.state[icons[i].key]}
            icon={icons[i]}
            key={icons[i].key} />
        );
      } else {
        Sentry.captureMessage(`Unknown picto (key='${icons[i].key}', label='${icons[i].label}').`);
      }
    }
    if (images.length < 1) return null;
    return (
      <div className="pictos">
        { images }
        {diameter && (
          <div className="diameter">
            <img alt="" className="pict-diameter" src="/images/pictos/picto_diameter.svg" />
            <span>{ diameter }</span>
          </div>
        )}
      </div>
    );
  }
}

export default Pictos;
