/* eslint-disable max-classes-per-file */

class TGDError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TGDError';
  }
}

class HttpError extends TGDError {
  constructor(message, code, response) {
    super(message);
    this.name = 'HttpError';
    this.code = code;
    this.response = response;
  }
}

class TimeoutError extends TGDError {
  constructor(message) {
    super(message);
    this.name = 'TimeoutError';
  }
}

export { TGDError, HttpError, TimeoutError };
