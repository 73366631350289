import React, { useContext } from 'react';
import ReactDOMServer from 'react-dom/server'
import AppContext from 'utils/AppContext';
import SoldierImage from 'components/SoldierImage';
import ProductPriceComment from 'components/product/ProductPriceComment';

function WatchTeaserContent(props) {
  const { watch } = props;
  const { size } = props;
  const context = useContext(AppContext);
  const { diameter } = watch;
  return (
    <div className="content">
      <div className="content-inside">
        <SoldierImage src={watch.getSoldierUrl('soldier')} />
        {size === 'full' && (
          <>
            {watch.stock !== null && !props.hide_stock && (
              <div className={`stock stock-${watch.stock}`}></div>
            )}
            {watch.novelty_category && !props.hide_novelties && (
              <div className="novelty-badge">new</div>
            )}
            <div className="infos">
              <h3>{watch.name}</h3>
              <div className="sku">{watch.originalSku}</div>
              {!context.hidePrice && watch.price !== null ?
                <div className="price" title={ReactDOMServer.renderToString(ProductPriceComment())}>{watch.price}<sup>*</sup></div> : null}
            </div>
            {diameter && !props.hide_diameter && (
              <div className="diameter">
                <img alt="" className="pict-diameter" src="/images/pictos/picto_diameter.svg" />
                <span>{ diameter }</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default WatchTeaserContent;
