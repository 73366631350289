import React from 'react';
import { Link } from 'react-router-dom'
import Strap from 'models/Strap';
import Pictos from 'components/Pictos';
import StrapTeaserContent from 'components/StrapTeaserContent';

function StrapTeaser(props) {
  const strap = props.strap instanceof Strap ? props.strap : new Strap(props.strap);
  const { context, data } = props;

  const size = 'size' in props ? props.size : 'full';
  const teaserClasses = ['watch', 'watch-teaser', 'watch-strap', 'strap-' + props.strap.sku];
  if (strap.soldier !== null) {
    teaserClasses.push('with-image');
  }
  const link = '/strap-detail/' + props.strap.sku + '?context=' + context + (data ? ('&data=' + data) : '');

  return (
    <div className={teaserClasses.join(' ')} key={strap.sku} style={props.style}>
      <Link to={link} >
        <StrapTeaserContent
          size={size}
          strap={strap} />
      </Link>
      <Pictos product={strap}/>
    </div>
  );
}

export default StrapTeaser;
