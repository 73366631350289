/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import dAddCounterback from 'assets/d_add_counterback.jpg';
import dAddSmallback from 'assets/d_add_smallback.jpg';
import dAddSquarevisu from 'assets/d_add_sqarevisu.jpg';
import dAddLargevisu from 'assets/d_add_largevisu.jpg';
import dAddExtension from 'assets/d_add_extension.jpg';
import dAddTrianglelogo from 'assets/d_add_trianglelogo.jpg';
import dAddBackHighlight from 'assets/d_add_backw_highlight.jpg';
import dAddCoverplate from 'assets/d_add_coverplate.jpg';
import dAddVs2c from 'assets/d_add_vs2c.jpg';
import dAddVs4c from 'assets/d_add_vs4c.jpg';
import dAddCoverplateCushions from 'assets/d_add_coverplatecushions.jpg';
import dAddRiser from 'assets/d_add_riser.jpg';
import dAddInfoplate from 'assets/d_add_infoplate.jpg';
import dAddHolder from 'assets/d_add_holder.jpg';
import dAddPricesupport from 'assets/d_add_pricesupport.jpg';
import dAddPricesupportDouble from 'assets/d_add_pricesupport_double.jpg';
import dAddPricesupport45 from 'assets/d_add_price_support_45.jpg';
import dAddPricesupport45Double from 'assets/d_add_price_support_45_double.jpg';
import dAddPricetags from 'assets/d_add_pricetags.jpg';
import dAddCushionspacer from 'assets/d_add_cushionspacer.jpg';
import dAddStrapclip from 'assets/d_add_strapclip.jpg';
import dAddPricecube from 'assets/d_add_pricecube.jpg';
import dAddGloves from 'assets/d_add_gloves.jpg';
import dAddSelvyt from 'assets/d_add_selvyt.jpg';
import dAddMirror from 'assets/d_add_mirror.jpg';
import dAddWatchtray from 'assets/d_add_watchtray.jpg';
import dAddRetailer from 'assets/d_add_retailer.jpg';
import dAddRedplate from 'assets/d_add_redplate.jpg';
import dAddQualityplate from 'assets/d_add_qualityplate.jpg';
import dSMediumBack from 'assets/d_s_medium_back.jpg';
import getI18nEn from 'i18nEn';
import ImageContent from './ImageContent';

import Ref from './Ref';

function DisplaysPageAdditional() {
  const { t } = useTranslation(null, { i18n: getI18nEn() });
  const [showMore, setShowMore] = useState(true);
    
  const switchState = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="content content-imagelinks" id="content-additional">
      <div className="container">
        <h2 className="main">{ t('Additional elements') }</h2>
        <div className="grid trailer-1">
          <div className="col-1">
            <ImageContent img={dAddCounterback}>
              <h3>{ t('Counter backwall') }</h3>
              <p><Ref>T801047186</Ref></p>
              { t('merchandising.displays.sizes', { w: 392, d: 16, h: 190 }) }
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={dAddSmallback}>
              <h3>{ t('Small backwall') }</h3>
              <p><Ref>T801047556</Ref></p>
              { t('merchandising.displays.sizes', { w: 392, d: 20, h: 370 }) }
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={dSMediumBack}>
              <h3>{ t('S-medium backwall') }</h3>
              <p><Ref>T801048842</Ref></p>
              { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 40, h: 470 }) }<br/>
              { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 40, h: 470 }) }
            </ImageContent>
          </div>
          <div className="col-1">
            <ImageContent img={dAddSquarevisu}>
              <h3>{ t('Square visual') }</h3>
              <p><Ref>T801047000</Ref></p>
              { t('merchandising.displays.sizes', { w: 370, d: 8, h: 400 }) }
              <h4>{ t('Includes') }</h4>
              • 2 Feet (Modular for H. & V. uses)<br/>
              • 1 Standard visual
            </ImageContent>
          </div>
        </div>
        {showMore && (
          <>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddLargevisu}>
                  <h3>{ t('Large visual') }</h3>
                  <p><Ref>T801046997</Ref></p>
                  { t('Horizontal') }: { t('merchandising.displays.sizes', { w: 400, d: 8, h: 240 }) }<br/>
                  { t('Vertical') }: { t('merchandising.displays.sizes', { w: 240, d: 8, h: 400 }) }
                  <h4>{ t('Includes') }</h4>
                  • 2 Feet (Modular for H. & V. uses)<br/>
                  • Fixation system for boutique backwall combination<br/>
                  • 1 Standard visual
                </ImageContent>
              </div>
              <div className="col-2">
                <ImageContent img={dAddExtension}>
                  <h3>{ t('Extension') }</h3>
                  <p><Ref>T801046987</Ref></p>
                  { t('Closed') }: { t('merchandising.displays.sizes', { w: 478, d: 122, h: 122 }) }<br/>
                  { t('Open') }: { t('merchandising.displays.sizes', { w: 854, d: 122, h: 122 }) }
                  <h4>{ t('Includes') }</h4>
                  <ul className="include-list">
                    <li>{ t('merchandising.displays.n-c-extensions', { count: 1, w: 7 }) }</li>
                    <li>{ t('merchandising.displays.n-s-extensions', { count: 2, w: 6 }) }</li>
                    <li>{ t('{{ n }} cushions', { n: 15 }) }</li>
                    <li>{ t('{{ n }} cushions supports', { n: 12 }) }</li>
                  </ul>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddBackHighlight}>
                  <h3>{ t('Backwall highlighter set') }</h3>
                  <p><Ref>T801047001</Ref></p>
                  { t('merchandising.displays.sizes', { w: 160, d: 60, h: 171 }) }
                  <h4>{ t('Includes') }</h4>
                  <ul className="include-list">
                    <li>{ t('merchandising.displays.n-plots', { count: 2, w: 6 }) }</li>
                    <li>{ t('{{ n }} cushions', { n: 6 }) }</li>
                    <li>{ t('{{ n }} cushions supports', { n: 9 }) }</li>
                  </ul>
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddTrianglelogo}>
                  <h3>{ t('Triangle logo') }</h3>
                  <p><Ref>T801046999</Ref></p>
                  { t('merchandising.displays.sizes', { w: 222, d: 35, h: 35 }) }<br/>
                  { t('Goes in front of side bases') }
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddCoverplate}>
                  <h3>{ t('Logo "cover plate"') }</h3>
                  <p><Ref>T801046998</Ref></p>
                  { t('Goes on central extension') }
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddVs2c}>
                  <h3>{ t('Visual support {{ count }} cushions', { count: 2 }) }</h3>
                  <p><Ref>T800047703</Ref></p>
                  { t('merchandising.displays.sizes', { w: 110, d: 3, h: 92 }) }
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddVs4c}>
                  <h3>{ t('Visual support {{ count }} cushions', { count: 4 }) }</h3>
                  <p><Ref>T800047704</Ref></p>
                  { t('merchandising.displays.sizes', { w: 110, d: 3, h: 184 }) }
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddCoverplateCushions}>
                  <h3>{ t('Cover plate') }</h3>
                  <p><Ref>T800047800</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddRiser}>
                  <h3>{ t('Infoplate riser') }</h3>
                  <p><Ref>T800047801</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddInfoplate}>
                  <h3>{ t('Infoplate') }</h3>
                  <p><Ref>T800046601</Ref> { t('to') } <Ref>T801047494</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddCushionspacer}>
                  <h3>{ t('Cushion spacer') }</h3>
                  <p><Ref>T800047797</Ref></p>
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddHolder}>
                  <h3>{ t('Pocket watch holder') }</h3>
                  <p><Ref>T800033349</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddStrapclip}>
                  <h3>{ t('Watch strap clip') }</h3>
                  <p><Ref>T800036696</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddPricecube}>
                  <h3>{ t('Price cube box') }</h3>
                  <ul className="include-list">
                    <li><Ref>T801037754</Ref></li>
                    <li><Ref>T801038006</Ref></li>
                    <li><Ref>T801038007</Ref></li>
                  </ul>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddPricesupport}>
                  <h3>{ t('Cushion price supports') }</h3>
                  <h4>{ t('Long single row') }</h4>
                  <p><Ref>T800048736</Ref></p>
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddPricesupportDouble}>
                  <h3>{ t('Cushion price supports') }</h3>
                  <h4>{ t('Long double row') }</h4>
                  <p><Ref>T800048739</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddPricesupport45}>
                  <h3>{ t('Cushion price tag') }</h3>
                  <h4>{ t('45° angle') }</h4>
                  <p><Ref>T800048737</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddPricesupport45Double}>
                  <h3>{ t('Cushion price tag') }</h3>
                  <h4>{ t('Double row') }</h4>
                  <p><Ref>T800048738</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddPricetags}>
                  <h3>{ t('Cushion price tag') }</h3>
                  <p><Ref>T800047799</Ref></p>
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddGloves}>
                  <h3>{ t('3 pairs of gloves (S/M & L)') }</h3>
                  <ul className="include-list">
                    <li><Ref>T802033116</Ref></li>
                    <li><Ref>T802033038</Ref></li>
                    <li><Ref>T802033115</Ref></li>
                  </ul>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddSelvyt}>
                  <h3>{ t('Selvyt') }</h3>
                  <p><Ref>T802033039</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddMirror}>
                  <h3>{ t('Mirror') }</h3>
                  <p><Ref>T800047213</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddWatchtray}>
                  <h3>{ t('Watch tray') }</h3>
                  <p><Ref>T802047713</Ref></p>
                </ImageContent>
              </div>
            </div>
            <div className="grid trailer-1">
              <div className="col-1">
                <ImageContent img={dAddRetailer}>
                  <h3>{ t('Retailter plate') }</h3>
                  <p><Ref>T800047216</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddQualityplate}>
                  <h3>{ t('Quality plate') }</h3>
                  <p><Ref>T800047212</Ref></p>
                </ImageContent>
              </div>
              <div className="col-1">
                <ImageContent img={dAddRedplate}>
                  <h3>{ t('Red collection plates') }</h3>
                  <p><Ref>T800047215</Ref></p>
                  <ul className="include-list cols">
                    <li>T-Touch</li>
                    <li>Connect Solar</li>
                    <li>T-Sport</li>
                    <li>Chrono XL</li>
                    <li>Seastar</li>
                    <li>T-Classic</li>
                    <li>Gentleman</li>
                    <li>Carson</li>
                    <li>Chemin Des Tourelles</li>
                    <li>Le Locle</li>
                    <li>PR100</li>
                    <li>T-Lady</li>
                    <li>Heritage</li>
                    <li>T-Gold</li>
                    <li>T-Pocket</li>
                    <li>Classic Dream</li>
                    <li>Tradition</li>
                    <li>Supersport</li>
                    <li>PRX</li>
                    <li>Bellissima</li>

                  </ul>
                </ImageContent>
              </div>
              <div className="col-1"></div>
            </div>
          </>
        )}
        {!showMore && (
          <button className="bt-show-more" type="button" onClick={switchState}>
            <h2>Show more</h2>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px"><path fill="#575756" d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24 13 L 24 24 L 13 24 L 13 26 L 24 26 L 24 37 L 26 37 L 26 26 L 37 26 L 37 24 L 26 24 L 26 13 L 24 13 z"/></svg>
          </button>
        )}
      </div>
      
    </div>
    
  );
}

export default DisplaysPageAdditional;
