import React from 'react';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function StrapsSearchLink(props) {
  const { t } = useTranslation();
  const url = '/straps-fromwatch/' + props.product.sku;
  
  if (!props.product.has_straps) {
    return null;
  }
  return (
    <Link className="btn" to={url}>
      {t('Strap accessorization')}
    </Link>
  );
}

export default StrapsSearchLink;
