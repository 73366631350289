import React from 'react';

class MerchandisingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fixed: false,
      active: 0,
      menuOpen: false,
      clicks: 0
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  getLis(elements) {
    const lis = [];
    for (let i = 0; i < elements.length; i++) {
      const liClass = elements[i].id === this.props.elements[this.state.active].id ? 'active' : '';
      lis.push(
        <li key={elements[i].id}>
          <a className={liClass} href={`#${elements[i].id}`} onClick={this.handleClick}>{ elements[i].text }</a>
        </li>
      );
    }
    return lis;
  }

  handleScroll(e) {
    const t = document.querySelector('.content-menu-static').offsetTop;
    const lim = t - document.querySelector('nav.navbar').offsetHeight;
    if (lim >= window.scrollY && this.state.fixed) {
      this.setState({ fixed: false });
    }
    else if (lim < window.scrollY && !this.state.fixed) {
      this.setState({ fixed: true });
    }
    let active = 0;
    if (this.props.elements) {
      for (let i = 0; i < this.props.elements.length; i++) {
        const el = document.getElementById(this.props.elements[i].id);
        const top = el.offsetTop + el.offsetHeight;
        if (top > window.scrollY + document.documentElement.clientHeight / 2) {
          active = i;
          break;
        }
      }
    }
    if (active !== this.state.active) {
      this.setState({ active: active });
    }
  }

  handleClick(e) {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.getAttribute('href').substring(1);
    let active = null;
    for (let i = 0; i < this.props.elements.length; i++) {
      if (this.props.elements[i].id === id) {
        active = i;
        break;
      }
    }
    if (active !== null) {
      this.setState((prevState) => ({
        active: active,
        clicks: prevState.clicks + 1
      }));
    }

  }

  handleMenuClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen
    }), () => {
      if (this.state.menuOpen) {
        window.addEventListener('click', this.closeMenu, { once: true });
      }
    });
  }

  closeMenu() {
    this.setState({
      menuOpen: false
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeMenu);
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clicks !== prevState.clicks) {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: document.getElementById(this.props.elements[this.state.active].id).offsetTop - 90
      });
    }
    if (this.state.fixed !== prevState.fixed) {
      if (this.state.fixed) {
        const h = document.querySelector('nav.navbar').offsetHeight;
        if (this.props.elements) document.querySelector('.content-menu-container').style.top = h + 'px';
      }

    }
  }

  render() {
    const { elements } = this.props;
    const lis = elements && this.getLis(elements);

    const mainClasses = ['content', 'content-menu', 'content-menu-static'];
    const mainClassesSecond = ['content', 'content-menu'];
    if (this.state.fixed) mainClassesSecond.push('fixed');
    if (this.state.menuOpen) mainClassesSecond.push('menu-opened');
    const from = elements && Math.min(elements.length - 1, Math.max(0, this.state.active - 0));
    const buttonLis = elements && this.getLis(elements.slice(from, from + 1));
    const goTop = () => {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: 0
      });
    };

    return (
      <>
        <div className={mainClasses.join(' ')}>
          { elements && (
            <div className="container">
              <div className="content-menu-menu">
                <button type="button" className="bt bt-open" onClick={this.handleMenuClick}>
                  <ul>
                    { buttonLis }
                  </ul>
                </button>
                <ul className="main-menu">
                  { lis }
                </ul>
              </div>
            </div>
          ) }
        </div>
        { this.state.fixed && (
          <>
            <div className={mainClassesSecond.join(' ')}>
              { elements && (
                <div className="content-menu-container">
                  <div className="container">
                    <div className="content-menu-menu">
                      <button type="button" className="bt bt-open" onClick={this.handleMenuClick}>
                        <ul>
                          { buttonLis }
                        </ul>
                      </button>
                      <ul className="main-menu">
                        { lis }
                      </ul>
                    </div>
                  </div>
                </div>
              ) }
            </div>
            <button type="button" className="btn-top" onClick={goTop}>
              top
            </button>
          </>
        ) }

      </>

    );
  }
}

export default MerchandisingMenu;
