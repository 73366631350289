import { getI18n } from './i18n';

let i18nEn = null;
const getI18nEn = () => {
  if (i18nEn === null) {
    i18nEn = getI18n().cloneInstance({ lng: 'en' });
  }
  return i18nEn;
};

export default getI18nEn;
