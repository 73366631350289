import React from 'react';
import { useTranslation } from 'react-i18next';
import { HttpError, TimeoutError } from 'utils/Errors';

function ErrorMessage(props) {
  const { error, message } = props;
  const { t } = useTranslation();
  let mess = message || t('error.default');
  let btnAction = null;
  if (error instanceof HttpError) {
    if (error.code === 403) {
      mess = t('error.forbidden');
    }
    else if (error.code === 503) {
      mess = t('Digital Sales Manual is temporarily unavailable due to scheduled maintenance. Please try to refresh in a few minutes.');
      btnAction = (
        <button className="btn" type="button" onClick={() => { window.location.reload(); }}>
          { t('Refresh') }
        </button>
      );
    } else if (error.code === 404) {
      mess = t('you try to access a resource that doesn\'t exists.');
      btnAction = (
        <a className="btn" href="/">
          { t('Home') }
        </a>
      );
    }
  }
  else if (error instanceof TimeoutError) {
    // eslint-disable-next-line max-len
    mess = t('Looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a while.');
    btnAction = (
      <button className="btn" type="button" onClick={() => { window.location.reload(); }}>
        { t('Refresh') }
      </button>
    );
  }
  return (
    <>
      <p className="sorry">{t('Sorry')},</p>
      <p>{mess}</p>
      { btnAction }
      { props.children }
    </>
  );
}

export default ErrorMessage;
