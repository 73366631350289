import React from 'react';
import i18n from 'i18next';
import i18nWatcher from 'utils/i18nWatcher';
import AppContext from 'utils/AppContext'

class CountrySwitcher extends React.Component {

  onChange = (e) => {
    const { setCountry } = this.context
    setCountry(e.target.value);

    i18nWatcher.getAll().forEach(function(comp) {
      comp.setState({
        isLoaded: false
      }, () => comp.load());
    });
  }

  render() {
    const { country, countries } = this.context;
    const options = [];
    let count = 0;
    for (const country_code in countries) {
      const country_name = countries[country_code].name[i18n.language];
      count += 1;
      const props = {
        key: country_code,
        value: country_code,
        sort: country_name,
      };
      options.push(
        <option {...props}>
          {country_name} ({country_code})
        </option>
      );
    }
    options.sort((a, b) => (a.props.sort.localeCompare(b.props.sort)));
    if (count < 2) {
      return null;
    }
    return (
      <select
        className="store-switcher pimp-my-select"
        onChange={this.onChange}
        defaultValue={country}>
        {options}
      </select>
    );
  }
}
CountrySwitcher.contextType = AppContext;

export default CountrySwitcher;
