import React, { useContext } from 'react';
import ProductsList from 'components/productlist/ProductsList';
import AppContext from 'utils/AppContext';

function ProductsPage(props) {
  const context = useContext(AppContext);
  return (
    <div className="container">
      <ProductsList
        collection={props.match.params.collection}
        isBackBtn={props.isBackBtn}
        filtersHash={props.filtersHash}
        country={context.country} />
    </div>
  );
}

export default ProductsPage;
