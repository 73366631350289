import Model from 'models/Model';
import imgGetUrl from 'utils/imgGetUrl';

class Strap extends Model {
  constructor(data, country, token) {
    super('api/straps/slug/{sku}');
    this.setGetParam('token', token);
    this.setGetParam('country', country);
    this._sku = null;
    this.images = {
      view: null, //
      lugs: null, //
      base: null, // Soldat
    };
    if (data) {
      this.data = data;
      this._sku = data.sku;
    }
  }

  set sku(sku) {
    if (this.params && 'sku' in this.params) {
      this.params.sku = sku;
    }
    this._sku = sku;
  }

  get sku() {
    return this._sku;
  }

  get originalSku() {
    return this._data.original_sku || this._sku;
  }

  get name() {
    return this._data.resource_name;
  }

  get price() {
    return this._data.price;
  }

  get soldier() {
    return this._data.image_soldier;
  }

  get stock() {
    return this._data.stock_indicator;
  }

  get isNovelty() {
    return this._data.novelty;
  }

  get video() {
    if ('video_url' in this._data && this._data.video_url) {
      return this._data.video_url.value;
    }
    return null;
  }

  get related() {
    return this._data.linked_product_items;
  }

  get launch_date() {
    return this._data.launch_date;
  }

  set data(data) {
    data.attrs = {};
    this._data = data;
  }

  getAttribute(name) {
    let value = null;
    let label = name;
    if (typeof name === 'string') {
      if (name in this._data) {
        const attr = this._data[name];
        if (Array.isArray(attr)) {
          value = attr.map((val) => val.label).join(', ');
        } else if (typeof attr === 'object' && attr !== null && 'label' in attr) {
          value = attr.label;
        } else {
          value = attr;
        }
      } else {
        console.error(`Attribute ${name} doesn't exists !`);
      }
      if (('fields' in this._data.meta) && (name in this._data.meta.fields)) {
        label = this._data.meta.fields[name];
      }
    }
    else {
      label = name.title;
      value = name.value(this._data);
    }

    return { value: value, label: label }
  };

  getSoldierUrl(style) {
    const imgInfos = imgGetUrl(style, this._data.image_soldier);
    return imgInfos.url;
  }

  getAllImages() {
    const allowed_types = ['lugs', 'zoom'];
    const images = [];
    let sizeTotal = 0;
    this._data.images.forEach((img) => {
      if (allowed_types.includes(img.type)) {
        const imgInfos = imgGetUrl(img.type, img.path);
        images.push(imgInfos);
        sizeTotal += 1;
      };
    });
    return {
      size: sizeTotal,
      images: images
    };
  }

  getLimitedAvailability() {
    if ('limited_availability' in this._data) {
      return this._data.limited_availability
    }
    return false
  }

  isSampleToOrder() {
    if ('is_sample' in this._data) {
      return this._data.is_sample
    }
    return false
  }

}

export default Strap;
