/* eslint no-return-assign: "error" */
import React from 'react';

class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
    
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  catchEvents(e) {
    e.stopPropagation();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onClose);
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.opened !== prevState.opened &&
      this.state.opened &&
      ('open' in this.props)
    ) {
      this.props.open();
    }
  }

  onOpen(e) {
    e.stopPropagation();
    this.setState(prevState => ({ opened: !prevState.opened }), () => {
      if (this.state.opened) {
        window.addEventListener('click', this.onClose, { once: true });
      }
    });
  }

  onClose() {
    this.setState({
      opened: false
    });
  }

  render() {
    /* eslint-disable jsx-a11y/no-static-element-interactions,
                      jsx-a11y/click-events-have-key-events,
                      max-len */
    const classes = ['modal-container'];
    const { children, name, state } = this.props;
    if (state) classes.push(state);
    if (name) classes.push('modal-' + name);
    if (!this.state.opened) {
      return null;
    }
    return (
      <div className={classes.join(' ')}>
        <div className="modal" onClick={this.catchEvents}>
          <button type="button" onClick={this.onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path
                fill="#000000"
                fillRule="evenodd"
                d="M10,8.58578644 L4.34314575,2.92893219 L2.92893219,4.34314575 L8.58578644,10 L2.92893219,15.6568542 L4.34314575,17.0710678 L10,11.4142136 L15.6568542,17.0710678 L17.0710678,15.6568542 L11.4142136,10 L17.0710678,4.34314575 L15.6568542,2.92893219 L10,8.58578644 Z">
              </path>
            </svg>
          </button>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
