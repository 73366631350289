import React from 'react';
import { useTranslation } from 'react-i18next';

function SoldierImage(props) {
  const { t } = useTranslation();
  if (props.src === null) {
    return (
      <div className="img-soldier img-soldier-alt">
        <span>[{t('Image not available')}]</span>
      </div>
    );
  }
  return (
    <img className="img-soldier" src={props.src} alt="" />
  );
}

export default SoldierImage;
