import React from 'react';
import { useTranslation, I18nextProvider } from 'react-i18next'
import boutiques from 'assets/m_boutiques.jpg';
import deco from 'assets/m_deco.jpg';
import tt from 'assets/m_tt.jpg';
import { Route, Switch, Link } from 'react-router-dom';
import getI18nEn from 'i18nEn';
import DisplaysPage from './merchandising/DisplaysPage';
import GuidelinesPage from './merchandising/GuidelinesPage';
import ImplementationPage from './merchandising/ImplementationPage';

function MerchandisingPage() {
  const { t } = useTranslation();
  return (
    <Switch>
      <Route exact path="/merchandising">
        <div className="merchandising links-page">
          <div className="container">
            <div className="grid">
              <div className="col-2 m-col-1">
              </div>
              <div className="col-2 m-col-3">
                <h1>{t('Merchandising')}</h1>
                <h2>{t('merchandising.subtitle')}</h2>
                <div className="links">
                  <Link to="/merchandising/displays">
                    <img src={tt} alt=""/>
                    {t('Displays And Accessories')}
                  </Link>
                  <Link to="/merchandising/guidelines">
                    <img src={deco} alt=""/>
                    {t('Merchandising Guidelines')}
                  </Link>
                  <Link to="/merchandising/implementation">
                    <img src={boutiques} alt=""/>
                    {t('Implementation And Case Studies')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Route>
      <I18nextProvider i18n={getI18nEn}>
        <Route path="/merchandising/displays" component={DisplaysPage} />
        <Route path="/merchandising/guidelines" component={GuidelinesPage} />
        <Route path="/merchandising/implementation" component={ImplementationPage} />
      </I18nextProvider>
    </Switch>

  );
}

export default MerchandisingPage
