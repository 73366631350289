import React from 'react';
import { useTranslation } from 'react-i18next';

function SampleToOrder(props) {
  const { t } = useTranslation();

  if (!props.product.isSampleToOrder()) {
    return null
  }
  return (
    <div className="limited-availability">
      <div className="badge limited-availability-badge">!</div>
      <div className="label">
        {t('Sample to Order')}
      </div>
    </div>
  );
}

export default SampleToOrder;
