import React from 'react';
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga';

function UserManualLink(props) {
  const { t } = useTranslation();
  const { link } = props;
  if (link === null || link === "") {
    return null;
  }

  const click = () => {
    ReactGA.event({
      category: 'Téléchargements',
      action: 'User manual'
    });
  };

  return (
    <a
      className="btn"
      onClick={click}
      href={ link }
      rel="noopener noreferrer"
      target="_blank">
      {t('Download user manual')}
    </a>
  );
}

export default UserManualLink;
