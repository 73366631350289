import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from 'utils/AppContext'

function ProductPriceComment() {
  const { t } = useTranslation();
  const { countries, country } = useContext(AppContext);
  const comments = {
    default: t('Retail price recommended'),
    germany: t('Recommended retail price including VAT'),
  }
  return comments[countries[country].price_info] || null;
}

export default ProductPriceComment;
