import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Storage from 'utils/Storage';

const AppContext = React.createContext();
const appActions = {};

export class AppContextProvider extends Component {
  constructor(props) {
    super(props);

    const params = new URLSearchParams(document.location.search);
    let token = params.get('token')
    if (token) {
      Storage.setItem('token', token, null, true);
    } else {
      token = Storage.getItem('token', true);
    }
    if (!token && window.location.hostname === 'salesmanual.tissotwatches.cn') {
      token = process.env.REACT_APP_CHINA_TOKEN;
      Storage.setItem('token', token, null, true);
    }

    this.filters = [
      'filter_dial_color',
      'filter_diameter',
      'filter_family',
      'filter_gender',
      'filter_movement_type',
      'filter_strap_type',
      'filter_subfamily',
    ];

    this.strapsFilters = [
      'filter_strap_type',
      'filter_strap_color_2',
      'filter_strap_buckle',
      'filter_strap_lugs',
    ];

    this.state = {
      languages: [],
      countries: [],
      country: null,
      user: null,
      token: token,
      activeFilters: [],
      error: null,
      hidePrice: Storage.getItem('hidePrice') === true,
    };

    appActions.setError = (err) => {
      this.setState({ error: err });
    };
  }

  updateLanguages = (languages) => {
    this.setState({
      languages: languages
    });
  };

  updateCountries = (countries) => {
    this.setState({
      countries: countries
    });
  };

  setCountry = (country) => {
    this.setState({
      country: country
    });
    ReactGA.set({ dimension2: country });
    Storage.setItem('country', country);
  };

  setUser = (user) => {
    this.setState({
      user: user,
      hidePrice: user.group === 'agentreader'
    });
  };

  setToken = (token) => {
    this.setState({
      token: token
    });
  };

  setHidePrice = (hidePrice) => {
    this.setState({
      hidePrice: hidePrice
    }, () => { Storage.setItem('hidePrice', hidePrice) });
  };

  addFilter = (filter) => {
    if (this.getFilter(filter.name) !== null) {
      this.removeFilter(filter);
    }
    this.setState(prevState => {
      const activeFilters = prevState.activeFilters.concat(filter);
      return ({ activeFilters: activeFilters });
    })
  };

  setFilters = (filters) => {
    this.setState({ activeFilters: filters });
  };

  removeFilter = (filter) => {
    this.setState(prevState => {
      const array = [...prevState.activeFilters];
      let index = null;
      for (let i = 0; i < array.length; i++) {
        if (filter.name === array[i].name) {
          index = i;
          break;
        }
      }
      if (index !== null) {
        array.splice(index, 1);
        return ({ activeFilters: array });
      }
      return null;
    })
  };

  resetFilters = (filter) => {
    const filters = (filter && filter === 'straps') ? this.strapsFilters : this.filters;
    this.setState(prevState => {
      const array = [...prevState.activeFilters];
      for (let i = array.length - 1; i >= 0; i--) {
        if (filters.indexOf(array[i].name) !== -1) {
          array.splice(i, 1);
        }
      }
      return ({ activeFilters: array });
    });
  };

  getFiltersHash = () => {
    this.state.activeFilters.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    const filters = [];
    for (let i = 0; i < this.state.activeFilters.length; i++) {
      const str = this.state.activeFilters[i].name + this.state.activeFilters[i].value;
      let hash = 0;
      for (let j = 0; j < str.length; j++) {
        hash += str.charCodeAt(j);
      }
      filters.push(hash % 99999);
    }
    return filters.join('-');
  }

  getFilter = (filter) => {
    const array = [...this.state.activeFilters];
    for (let i = 0; i < array.length; i++) {
      if (filter === array[i].name) {
        return (array[i].value);
      }
    }
    return null;
  };

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider
        value={{
          updateLanguages: this.updateLanguages,
          languages: this.state.languages,
          updateCountries: this.updateCountries,
          countries: this.state.countries,
          setCountry: this.setCountry,
          country: this.state.country,
          setUser: this.setUser,
          user: this.state.user,
          setToken: this.setToken,
          token: this.state.token,
          setHidePrice: this.setHidePrice,
          hidePrice: this.state.hidePrice,
          activeFilters: this.state.activeFilters,
          addFilter: this.addFilter,
          removeFilter: this.removeFilter,
          resetFilters: this.resetFilters,
          setFilters: this.setFilters,
          getFilter: this.getFilter,
          getFiltersHash: this.getFiltersHash,
          getFiltersList: () => this.filters,
          getStrapsFiltersList: () => this.strapsFilters,
          error: this.state.error
        }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}

export { appActions };
export default AppContext
