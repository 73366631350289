import Model from 'models/Model';

class Categories extends Model {
  constructor(country, token) {
    super('api/categories');
    this.setGetParam('type', 'current_collections')
    this.setGetParam('country', country);
    this.setGetParam('token', token);
    this._hierarchy = {
      children: null,
      id: null,
      index: 0,
      depth: 0
    };
  }

  getList(path) {
    let ret = this._hierarchy;
    for (let i = 0; i < path.length; i++) {
      ret = ret.children[path[i]];
    }
    return ret;
  }

  set data(data) {
    this._data = data;
    this.parseHierarchy();
  }

  parseHierarchy() {
    this._hierarchy.children = [{
      index: 0,
      name: null,
      depth: 1,
      id: null,
      children: this.walkHierarchy(this._data.current_collections, 2)
    }];
  }

  walkHierarchy(data, depth) {
    const children = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      children.push({
        children: this.walkHierarchy(el.children, depth + 1),
        name: el.name,
        id: el.pk,
        depth: depth
      })
    }
    for (let i = 0; i < children.length; i++) {
      children[i].index = i;
    }
    return children;
  }
}

export default Categories;
